.box-contactos {
    border-radius: 24px;
    border: 1px solid rgba(83, 179, 198, 0.40);
    background: #FFF;
    padding: 30px;
    margin: 35px 0 0 0;

    @include max-lg{
        padding: 20px;
    }

    &:first-child{
        margin: 0;
    }

    .box-options-contacto{
        padding: 50px 0 0 0;
        position: relative;

        @include max-lg{
            padding: 20px 0 0 40px;
        }

        &:first-child{
            padding: 0;

            @include max-lg{
                padding: 20px 0 0 40px;
            }
        }

        &:nth-child(2){
            padding: 0;

            @include max-lg{
                padding: 20px 0 0 40px;
            }
        }

        i{
            position: absolute;
            left: -47px;
            transform: rotate(-90deg);
            font-size: 30px;
            width: 30px;
            height: 21px;

            @include max-lg{
                left: -5px;
            }

            &:before{
                color: $color-primary3;
            }
        }
    }

    h2{
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 700;
        margin: 0;
    }

    h3{
        font-size: 14px;
        font-weight: 700;
        line-height: 160%;
        margin: 0 0 5px 0;
        text-transform: uppercase;
    }

    p{
        color: $color-primary5;
        margin: 0;

        @include max-lg{
            font-size: 14px;
        }
    }
}

.box-contactos-form{
    margin: 150px 0;
    padding: 60px;
    position: relative;
    border-radius: 24px;
    border: 1px solid $color-primary;
    background: $color-primary4;

    @include max-lg{
        margin: 50px 0 80px 0;
        padding: 40px 20px 20px 20px;
    }

    &.box-recrutamento{
        margin: 60px 0 100px 0;
    }

    .validate-form {
        position: initial;
    }

    .form-active{

        h2{
            color: $color-primary;
            font-size: 25px;
            font-weight: 700;
            position: absolute;
            top: -16px;
            padding: 0 20px;
            left: 45px;
            background: $color-primary4;
        }
    
        .form-group{
    
            .form-check{
                margin-top: 45px;

                @include max-lg{
                    margin-top: 20px;
                }
    
                input {
                    padding: 0;
                    border-radius: 5px;
                    margin-top: 15px;
                }
    
                label {
                    margin-left: 10px;
                    margin-top: 15px;
    
                    &.error{
                        margin: 0;
                        margin-left: 10px;
                        text-decoration: none;
                    }
                }
            }
    
            label {
                color: #014150;
                font-family: Montserrat;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 160%;
                margin-left: 20px;
                margin-bottom: 6px;
    
                &.error{
                    font-weight: 400;
                    font-size: 13px;
                    color: red;
                }
    
                &.form-check-label {
                    text-decoration: none;
                    font-weight: 400;

                    @include max-lg{
                        padding-left: 20px;
                        margin-left: 0;
                    }
                }
            }
    
            input{
                padding: 10px 8px 10px 20px;
                border-radius: 24px;
                border: 1px solid $color-primary3;
                background: rgba(255, 255, 255, 0.50);
            }
            
            textarea{
                padding: 10px 8px 10px 20px;
                border-radius: 24px;
                border: 1px solid $color-primary3;
                background: rgba(255, 255, 255, 0.50);
            }

            .form-check {
                @include max-lg{
                    padding-left: 0;
                }

                .form-check-input {
                    @include max-lg{
                        margin-left: 10px;
                    }
                }

                &.rgpd-box{
                    a{
                        color: $color-primary;
                        text-decoration: underline;
                    }

                    input[type=checkbox]:checked{
                        background-color: $color-primary3;
                        background-image: var(--loba-form-check-bg-image);;
                    }
                }
            }

            .main-btn-submit{
                padding: 20px 24px 20px 34px;
                border-radius: 100px;
                background: $color-primary;
                font-size: 14px;
                font-weight: 700;
                display: inline-flex;
                float: right;
                transition: 0.2s linear;
                border: none;

                @include max-lg{
                    justify-content: space-between;
                    float: none;
                    width: 100%;
                    margin: 30px 0 0 0;
                }
    
                &:hover{
                    background: $color-primary3;
                    transition: 0.2s linear;
                }
    
                i {
                    font-size: 30px;
                    width: 30px;
                    height: 30px;
                    margin-top: -9px;
                    margin-left: 8px;            
    
                    &:before{
                        color: $white;
                    }
                }
            } 
        }
    }

    .form-thankyou{
        padding: 0 300px;

        h2{
            font-size: 25px;
            font-weight: 700;
            line-height: 160%;
            color: $color-primary;
            margin: 30px 0 0 0;
        }
    }
}

.required-info{
    color: $color-primary;
    font-size: 13px;
    display: block;
    font-weight: 500;
    margin: 0 0 30px 0;
}