// Components
//
// Define common padding and border radius sizes and more.

$border-width:                1px !default;

$border-style:                solid !default;
$border-color:                $gray-300 !default;
$border-color-translucent:    rgba($black, .175) !default;


$border-radius:               .375rem !default;
$border-radius-xs:            .125rem !default;
$border-radius-sm:            .25rem !default;
$border-radius-lg:            .5rem !default;
$border-radius-xl:            1rem !default;
$border-radius-xxl:           2rem !default;
$border-radius-pill:          50rem !default;

$box-shadow:                  0 .5rem 1rem rgba($black, .15) !default;
$box-shadow-sm:               0 .125rem .25rem rgba($black, .075) !default;
$box-shadow-lg:               0 1rem 3rem rgba($black, .175) !default;
$box-shadow-inset:            inset 0 1px 2px rgba($black, .075) !default;

$component-active-color:      $white !default;
$component-active-bg:         $primary !default;

$focus-ring-width:      .25rem !default;
@if $enable-focus-shadow != true {
    $focus-ring-width: 0;
}
$focus-ring-opacity:    .25 !default;
$focus-ring-color:      rgba($primary, $focus-ring-opacity) !default;
$focus-ring-blur:       0 !default;
$focus-ring-box-shadow: 0 0 $focus-ring-blur $focus-ring-width $focus-ring-color !default;

$caret-width:                 .3em !default;
$caret-vertical-align:        $caret-width * .85 !default;
$caret-spacing:               $caret-width * .85 !default;

$transition-base:             all .2s ease-in-out !default;
$transition-fade:             opacity .15s linear !default;

$transition-collapse:         height .35s ease !default;
$transition-collapse-width:   width .35s ease !default;