@font-face {
  font-family: 'icomoon';
  src:  url('#{$font-path}/icomoon.eot?q4nivx');
  src:  url('#{$font-path}/icomoon.eot?q4nivx#iefix') format('embedded-opentype'),
    url('#{$font-path}/icomoon.ttf?q4nivx') format('truetype'),
    url('#{$font-path}/icomoon.woff?q4nivx') format('woff'),
    url('#{$font-path}/icomoon.svg?q4nivx#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ic-facebook:before {
  content: "\e919";
  color: #53b3c6;
}
.icon-ic-instagram:before {
  content: "\e91a";
  color: #53b3c6;
}
.icon-ic-linkedin:before {
  content: "\e91b";
  color: #53b3c6;
}
.icon-ic-x:before {
  content: "\e91c";
  color: #53b3c6;
}
.icon-ic-youtube:before {
  content: "\e91d";
  color: #53b3c6;
}
.icon-arrow-down:before {
  content: "\e900";
}
.icon-arrow-right:before {
  content: "\e901";
}
.icon-arrow-right-blue:before {
  content: "\e902";
  color: #006e87;
}
.icon-attachment:before {
  content: "\e903";
  color: #006e87;
}
.icon-calendar:before {
  content: "\e904";
  color: #006e87;
}
.icon-check:before {
  content: "\e905";
}
.icon-delete:before {
  content: "\e906";
  color: #006e87;
}
.icon-doc-variant:before {
  content: "\e907";
}
.icon-down-arrow:before {
  content: "\e908";
  color: #006e87;
}
.icon-download:before {
  content: "\e909";
  color: #006e87;
}
.icon-eye:before {
  content: "\e90a";
  color: #006e87;
}
.icon-menu:before {
  content: "\e90b";
  color: #006e87;
}
.icon-menu-close:before {
  content: "\e90c";
  color: #006e87;
}
.icon-minus:before {
  content: "\e90d";
}
.icon-photo:before {
  content: "\e90e";
}
.icon-plus:before {
  content: "\e90f";
}
.icon-search:before {
  content: "\e910";
  color: #006e87;
}
.icon-send:before {
  content: "\e911";
  color: #014150;
}
.icon-send-2:before {
  content: "\e912";
  color: #014150;
}
.icon-share:before {
  content: "\e913";
  color: #006e87;
}
.icon-simple-arrow:before {
  content: "\e914";
  color: #006e87;
}
.icon-up:before {
  content: "\e915";
  color: #014150;
}
.icon-upload:before {
  content: "\e916";
  color: #006e87;
}
.icon-user:before {
  content: "\e917";
  color: #006e87;
}
.icon-user-menu:before {
  content: "\e918";
  color: #006e87;
}
