// ===== BUTTONS ===== //
// Try styling buttons by changing the variable values

.btn-default {
	--#{$prefix}btn-color: #{$color-default-darker};
	--#{$prefix}btn-bg: #{$color-default-lighter};

	--#{$prefix}btn-border-color: #{$color-default-lighter};
	--#{$prefix}btn-hover-color: #{$color-default-darker};
	--#{$prefix}btn-hover-bg: #{$color-default3};
	--#{$prefix}btn-hover-border-color: #{$color-default3};

	--#{$prefix}btn-focus-shadow-rgb: 243, 246, 249;

	--#{$prefix}btn-active-color: #{$color-default-darker};
	--#{$prefix}btn-active-bg: #{$color-default3};
	--#{$prefix}btn-active-border-color: #{$color-default3};
	--#{$prefix}btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);

	--#{$prefix}btn-disabled-color: #{$color-default};
	--#{$prefix}btn-disabled-bg: #{$color-default-lighter};
	--#{$prefix}btn-disabled-border-color: #{$color-default-lighter};

	--#{$prefix}btn-border-radius: var(--#{$prefix}border-radius);
	--#{$prefix}btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
	--#{$prefix}btn-disabled-opacity: 0.65;
	--#{$prefix}btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--#{$prefix}btn-focus-shadow-rgb), .5);
}

.btn-outline-default {
	--#{$prefix}btn-color: #{$color-default-darker};
	--#{$prefix}btn-bg: transparent;

	--#{$prefix}btn-border-color: #{$color-default3};
	--#{$prefix}btn-hover-color: #{$color-default-darker};
	--#{$prefix}btn-hover-bg: #{$color-default3};
	--#{$prefix}btn-hover-border-color: #{$color-default3};

	--#{$prefix}btn-focus-shadow-rgb: 243, 246, 249;

	--#{$prefix}btn-active-color: #{$color-default-darker};
	--#{$prefix}btn-active-bg: #{$color-default3};
	--#{$prefix}btn-active-border-color: #{$color-default3};
	--#{$prefix}btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);

	--#{$prefix}btn-disabled-color: #{$color-default};
	--#{$prefix}btn-disabled-bg: transparent;
	--#{$prefix}btn-disabled-border-color: #{$color-default-lighter};
  }


//
// Button Sizes
//

.btn-xs {
	@include button-size($btn-padding-y-xs, $btn-padding-x-xs, $btn-font-size-xs, $btn-border-radius-xs)
}

.btn-sm {
	@include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-border-radius-sm);
}

.btn-lg {
	@include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-border-radius-lg);
}

.btn-xl {
	@include button-size($btn-padding-y-xl, $btn-padding-x-xl, $btn-font-size-xl, $btn-border-radius-lg)
}

.btn-block {
	display: block;
	width: 100%;
}

.btn{
	&.btn-form-1{
		background-color: $color-primary3;
		border-radius: 100px;
		padding: 10px 20px 10px 34px;
		color: $white;
		font-weight: 700;
		font-size: 14px;
		display: inline-flex;
		align-items: center;
		margin-left: 35px;

		@include max-lg{
			margin-left: 0;
			margin-top: 35px;
			width: 100%;
			justify-content: space-between;
		}

		i{
			&::before{
				font-size: 40px;
				height: 40px;
				display: block;
				margin-left: 10px;
			}
		}

		&:hover{
			background-color: $hover-button-color;

			i{
				&::before{
					color: $white;
				}
			}
		}

		&:focus{
			background-color: $hover-button-color;

			i{
				&::before{
					color: $white;
				}
			}
		}
	}

	&.btn-read-more{
		background-color: $color-primary;
		border-radius: 100px;
		padding: 10px 20px 10px 34px;
		color: $white;
		font-weight: 700;
		font-size: 14px;
		display: inline-flex;
		align-items: center;

		@include max-md{
			margin-left: 0;
			margin-top: 35px;
			width: 100%;
			justify-content: space-between;
		}

		span{
			&::before{
				font-size: 40px;
				height: 40px;
				display: block;
				margin-left: 10px;
			}
		}

		&:hover{
			background-color: $hover-button-color2;

			i{
				&::before{
					color: $white;
				}
			}
		}

		&:focus{
			background-color: $hover-button-color2;

			i{
				&::before{
					color: $white;
				}
			}
		}
	}

	&.btn-read-more-outline{
		border: 1px solid rgba(1, 65, 80, 0.40);
		border-radius: 100px;
		padding: 5px 15px 5px 29px;
		color: $color-primary;
		font-weight: 700;
		font-size: 14px;
		display: inline-flex;
		align-items: center;

		@include max-md{
			margin-left: 0;
			margin-top: 35px;
			width: 100%;
			justify-content: space-between;
		}

		span{
			&::before{
				font-size: 40px;
				height: 40px;
				display: block;
				margin-left: 10px;
				color: $color-primary3;
			}
		}

		&:hover{
			border: 1px solid rgba(1, 65, 80, 0.40);
			background: #D4F0F6;

			i{
				&::before{
					color: $white;
				}
			}
		}

		&:focus{
			border: 1px solid rgba(1, 65, 80, 0.40);
			background: #D4F0F6;

			i{
				&::before{
					color: $white;
				}
			}
		}
	}
}

.pagination-more{
    padding: 100px 0 200px 0;
    text-align: center;
    width: auto;
    margin: 0 auto;

	@include max-lg{
		padding: 100px 0;
	}

    a{
        display: flex;
        padding: 20px 40px 20px 34px;
        align-items: center;
        gap: 10px;
        border-radius: 100px;
        background: $color-primary;
        color: $white;
        text-decoration: none;
        font-size: 14px;
        font-weight: 700;
        transition: 0.2s linear;
        position: relative;

        &:hover{
            transition: 0.2s linear;
            background: $color-primary3;
        }

        i{
            font-size: 36px;
            width: 20px;
            height: 20px;

            &:before {
                position: absolute;
                right: 15px;
                top: 13px;
            }
        }
    }
}

.button-banner{
    padding: 10px 0 0 0;
    text-align: right;
    width: auto;
    margin: 0 auto;

	@include max-lg{
		text-align: center;
		margin: 0 auto 20px 0;
	}

    a{
        padding: 20px 40px 20px 34px;
        align-items: center;
        gap: 10px;
        border-radius: 100px;
        background: $color-primary;
        color: $white;
        text-decoration: none;
        font-size: 14px;
        font-weight: 700;
        transition: 0.2s linear;
        position: relative;

        &:hover{
            transition: 0.2s linear;
            background: $color-primary3;
        }

        i{
            font-size: 36px;
            width: 20px;
            height: 20px;
			margin-left: 15px;

            &:before {
                position: absolute;
                right: 15px;
                top: 13px;
            }
        }
    }
}

.pagination-numbers{
	ul{
		list-style: none;
		padding: 80px 0 120px 0;
		display: flex;
		gap: 10px;
		margin: 0;
		place-content: center;

		li{
			a{
				display: flex;
				align-items: center;
				justify-content: center;
				width: 44px;
				height: 44px;
				border-radius: 100px;
				font-size: 14px;
				font-weight: 700;
				color: $color-primary5;
				text-decoration: none;
				transition: 0.2s linear;

				&:hover{
					background-color: $color-primary;
					color: $white;
					transition: 0.2s linear;
				}

				&.active{
					background-color: $color-primary;
					color: $white;
					transition: 0.2s linear;
				}
			}
		}
	}
}