.banner-pages{
    padding: 60px 0;

    @include max-lg{
        padding: 30px 0;
    }
    
    h1{
        font-size: 3.8125rem;
        margin-top: 40px;

        @include max-lg{
            font-size: 2rem;
            margin-top: 30px;
        }
    }

    .p-large p{
        margin-top: 30px;
    }

    .update-date{
        text-align: right;
        font-size: 13px;
        color: $color-primary;
        font-weight: 500;

        @include max-lg{
            text-align: left;
            padding-left: 25px;
        }

        i{
            font-size: 27px;
            position: relative;
              
            &:before{
                color: $color-primary2;
                position: absolute;
                left: -30px;
                top: 7px;
            }
        }
    }

    .meta-posts-banner {
        display: inline-flex;
        gap: 40px;
        margin-top: 20px;

        .author {
            font-size: 14px;
            line-height: 160%;
            color: $color-primary;
            display: inline-flex;
            align-items: center;
            width: 100%;
            white-space: nowrap;
    
            i {
                font-size: 36px;
                margin-right: 5px;
                margin-left: -5px;
            }
        }
    
        .date-post {
            font-size: 14px;
            line-height: 160%;
            color: $color-primary;
            display: inline-flex;
            align-items: center;
            width: 100%;
    
            i {
                font-size: 36px;
                margin-right: 5px;
                margin-left: -5px;
            }
        }
    }
}

.breadcrumbs {
    list-style: none;
    padding: 0;
    display: inline-flex;
    margin: 0 0 10px 0;

    @include max-lg{
        display: flow;
    }

    li{
        display: inline-flex;
        align-items: center;
        
        &:after{
            content: "";
            background-image: url('../../assets/icons/simple-arrow.svg');
            display: block;
            width: 10px;
            height: 10px;
            background-position: 50%;
            margin: 0 6px;
        }

        &:last-child{
            &:after{
                display: none;
            }
        }

        a{
            color: $color-primary2;
            text-decoration: none;

            &:hover{
                text-decoration: underline;
            }
        }
    }
}

.back-posts{
    display: flex;
    width: fit-content;
    font-size: $font-size-base;
    font-weight: 400;
    line-height: 160%;
    text-decoration: none;
    color: $color-primary2;

    i{
        font-size: 36px;
        transform: rotate(180deg);
        display: inline-flex;
        height: 22px;
        width: 24px;
        margin-top: 8px;
    }
}

.square-markbrand{
    background-image: url('../../assets/img/vector-banner/brand-dark.svg');
    width: 1001px;
    height: 891px;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;

    @include max-lg{
        display: none;
    }
}