.nav-is-toggled {
	.nav-drill {
		transform: translateX(0);
	}
	&::after {
		opacity: 1;
		visibility: visible;
	}

    .nav-top {
        .hamburger {
            i.ic-open{
                display: none;
            }

            i.ic-close{
                display: block;
            }
        }
    }
}

.nav-top {
	display: flex;
	align-items: end;
	position: fixed;
	z-index: 101;
    padding: 10px 0;
    right: 10px;
    top: 5px;
	
	.hamburger {
		color: $white;
		cursor: pointer;
        font-size: 60px;
        display: flex;
        align-items: center;
        justify-content: center;

        i.ic-close{
            display: none;
        }

        i.ic-open{
            display: block;
        }

        .tit-menuico{
            color: $color-primary;
            font-size: 13px;
            position: absolute;
            font-weight: 600;
            bottom: 3px;
        }
	}
}
.nav-drill {
	margin-top: 50px;
	transform: translateX(100%);
}

// ---------------------------------------
// important stuff starts here

$nav-width: 250px;
$nav-padding: 0.875em 1em;
$nav-background-color: $white;

.menu-mobile{
    .nav {
        &-drill {
            display: flex;
            position: fixed;
            z-index: 100;
            top: 0;
            right: 0;
            width: 100%;
            height: 100vh;
            background-color: $color-primary4;
            overflow-y: auto;
            overflow-x: hidden;
            -webkit-overflow-scrolling: touch;
            transition: 0.45s;
            margin: 0;
            padding-top: 120px;
        }
        
        &-items {
            flex: 0 0 100%;
            padding: 0 20px;
            list-style: none;
        }
        
        &-item {
            &:not(:last-child) {
                border-bottom: solid 1px $color-primary;
                /*margin: 0 20px;*/
            }

            &.area-reservada{
                margin: 50px 20px 0 20px;

                a {
                    background-color: #D4F0F6;
                    border-radius: 6px;
                    padding: 14px 20px 14px 65px;
                    border: 1px solid #BAE1E8;
                    
                    i{
                        right: inherit;
                        left: 30px;
                        margin-top: -13px;
                        color: $color-primary2;
                        font-size: 50px;
                        z-index: 1;
                    }
                }
            }
        }
        
        &-link {
            display: block;
            padding: $nav-padding;
            color: $color-primary2;
            font-size: 1rem;
            line-height: 1.5em;
            font-weight: 700;
            padding-left: 0;
    
            i {
                font-size: 30px;
                width: 30px;
                height: 30px;
                display: inline-block;
                position: absolute;
                right: 20px;
                z-index: -1;
            }
        }
        
        &-expand {
            &-content {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transform: translateX(100%);
                background-color: $color-primary4;
                transition: 0.3s;
                visibility: hidden;
                padding-top: 120px;
                
                .nav-item {
                    &:not(:last-child) {
                        border-bottom: solid 1px rgb(107 107 107);
    
                        a{
                            background-color: $color-primary;
                        }
                    }

                    &:first-child{
                        a{
                            background-color: $color-primary4;
                        }

                        .nav-link{
                            padding: $nav-padding;
                            color: $color-primary2;
                            font-size: 1rem;
                            line-height: 1.5em;
                            font-weight: 700;
                            padding-left: 40px;

                            i {
                                left: 25px;
                                color: $color-primary2;
                                transform: rotate(180deg);
                                margin-top: 5px;
                                font-size: 40px;
                            }
                        }
                    }
    
                    &:nth-child(2){
                        a{
                            background-color: $color-primary4;
                        }

                        .nav-link{
                            padding: $nav-padding;
                            color: $color-primary2;
                            font-size: 1rem;
                            line-height: 1.5em;
                            font-weight: 700;
                            padding-left: 0;
                        }
                    }

                    &:nth-child(3) a{
                        padding-top: 20px;
                    }

                    &:last-child a{
                        padding-bottom: 20px;
                        background-color: $color-primary;
                    }
                }
    
                .nav-link {
                    background-color: $color-primary4;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 140%;
                    padding-left: 50px;
                    color: $white;

                    i{
                        left: 30px;
                        right: inherit;
                        color: $white;
                        transform: rotate(-45deg);
                        margin-top: -5px;
                        z-index: 1;
                    }
                }
    
                .nav-back-link {
                    display: flex;
                    align-items: center;
                    color: $color-primary;
                }
            }
            
            &-link {
                display: flex;
                justify-content: space-between;
            }
            
            &.active {
                > .nav-expand-content {
                    transform: translateX(0);
                    visibility: visible;
                    padding-top: 120px;
                }

                & ~ .area-reservada{display: none;}
            }
        }
    }    
}