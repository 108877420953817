// Input Button
//

$input-btn-padding-y:         .375rem !default;
$input-btn-padding-x:         .75rem !default;
$input-btn-font-family:       null !default;
$input-btn-font-size:         $font-size-base !default;
$input-btn-line-height:       $line-height-base !default;

$input-btn-focus-width:         $focus-ring-width !default;
$input-btn-focus-color-opacity: $focus-ring-opacity !default;
$input-btn-focus-color:         $focus-ring-color !default;
$input-btn-focus-blur:          $focus-ring-blur !default;
$input-btn-focus-box-shadow:    $focus-ring-box-shadow !default;

$input-btn-padding-y-xs:      .125rem !default;
$input-btn-padding-x-xs:      .25rem !default;
$input-btn-font-size-xs:      $font-size-sm*.9 !default;

$input-btn-padding-y-sm:      .25rem !default;
$input-btn-padding-x-sm:      .5rem !default;
$input-btn-font-size-sm:      $font-size-sm !default;

$input-btn-padding-y-lg:      .5rem !default;
$input-btn-padding-x-lg:      1rem !default;
$input-btn-font-size-lg:      $font-size-lg !default;

$input-btn-padding-y-xl:      1rem !default;
$input-btn-padding-x-xl:      2rem !default;
$input-btn-font-size-xl:      $font-size-lg*1.2 !default;

$input-btn-border-width:      var(--#{$prefix}border-width) !default;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-color:                   var(--#{$prefix}body-color) !default;
$btn-padding-y:               $input-btn-padding-y !default;
$btn-padding-x:               $input-btn-padding-x !default;
$btn-font-family:             $input-btn-font-family !default;
$btn-font-size:               $input-btn-font-size !default;
$btn-line-height:             $input-btn-line-height !default;
$btn-white-space:             null !default; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-xs:            $input-btn-padding-y-xs !default;
$btn-padding-x-xs:            $input-btn-padding-x-xs !default;
$btn-font-size-xs:            $input-btn-font-size-xs !default;

$btn-padding-y-sm:            $input-btn-padding-y-sm !default;
$btn-padding-x-sm:            $input-btn-padding-x-sm !default;
$btn-font-size-sm:            $input-btn-font-size-sm !default;

$btn-padding-y-lg:            $input-btn-padding-y-lg !default;
$btn-padding-x-lg:            $input-btn-padding-x-lg !default;
$btn-font-size-lg:            $input-btn-font-size-lg !default;

$btn-padding-y-xl:            $input-btn-padding-y-xl !default;
$btn-padding-x-xl:            $input-btn-padding-x-xl !default;
$btn-font-size-xl:            $input-btn-font-size-xl !default;

$btn-border-width:            $input-btn-border-width !default;

$btn-font-weight:             $font-weight-normal !default;
$btn-box-shadow:              inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075) !default;
$btn-focus-width:             $input-btn-focus-width !default;
$btn-focus-box-shadow:        $input-btn-focus-box-shadow !default;
$btn-disabled-opacity:        .65 !default;
$btn-active-box-shadow:       inset 0 3px 5px rgba($black, .125) !default;

$btn-link-color:              var(--#{$prefix}link-color) !default;
$btn-link-hover-color:        var(--#{$prefix}link-hover-color) !default;
$btn-link-disabled-color:     $gray-600 !default;
$btn-link-focus-shadow-rgb:   to-rgb(mix(color-contrast($link-color), $link-color, 15%)) !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           var(--#{$prefix}border-radius) !default;
$btn-border-radius-xs:        var(--#{$prefix}border-radius-xs) !default;
$btn-border-radius-sm:        var(--#{$prefix}border-radius-sm) !default;
$btn-border-radius-lg:        var(--#{$prefix}border-radius-lg) !default;
$btn-border-radius-xl:        var(--#{$prefix}border-radius-xl) !default;

$btn-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

$btn-hover-bg-shade-amount:       15% !default;
$btn-hover-bg-tint-amount:        15% !default;
$btn-hover-border-shade-amount:   20% !default;
$btn-hover-border-tint-amount:    10% !default;
$btn-active-bg-shade-amount:      20% !default;
$btn-active-bg-tint-amount:       20% !default;
$btn-active-border-shade-amount:  25% !default;
$btn-active-border-tint-amount:   10% !default;