// ===== COLORS ===== //

$blue:                    #0d6efd !default;
$indigo:                  #6610f2 !default;
$purple:                  #6f42c1 !default;
$pink:                    #d63384 !default;
$red:                     #dc3545 !default;
$orange:                  #fd7e14 !default;
$yellow:                  #ffc107 !default;
$green:                   #198754 !default;
$teal:                    #20c997 !default;
$cyan:                    #0dcaf0 !default;

//Service colors

$service-yellow: #F9F7A0;
$service-light-gray: #E7EAF6;
$service-dark-green: #8ED7CE;
$service-orange: #FEDCC3;
$service-green: #9DF0AC;
$service-gray: #E5DDDA;
$service-light-green: #E8FFE8;
$service-red: #FFB997;
$service-blue: #C1EAF2;
$service-other-green: #E9F679;

$color-primary:           #014150;
$color-primary2:          #006E87;
$color-primary3:          #53B3C6;
$color-primary4:          #EBFBFF;
$color-primary5:          #272727;
$color-primary6:          #014150;
$color-primary-lighter:     $color-primary2;
$color-primary-darker:      $color-primary6;
$primary: $color-primary    !default;
$hover-button-color: #3D98AA;
$hover-button-color2: #53B3C6;

$color-accent:            #673ab7;
$color-accent2:             tint-color($color-accent, 80%);
$color-accent3:             tint-color($color-accent, 40%);
$color-accent4:             tint-color($color-accent, 20%);
$color-accent5:             shade-color($color-accent, 40%);
$color-accent6:             shade-color($color-accent, 60%);
$color-accent-lighter:      $color-accent2;
$color-accent-darker:       $color-accent6;
$secondary:                 $color-accent !default;

$white:                   #fff !default;
$gray-100:                #f8f9fa !default;
$gray-200:                #e9ecef !default;
$gray-300:                #dee2e6 !default;
$gray-400:                #ced4da !default;
$gray-500:                #adb5bd !default;
$gray-600:                #6c757d !default;
$gray-700:                #495057 !default;
$gray-800:                #343a40 !default;
$gray-900:                #212529 !default;
$black:                   #000 !default;

// Usually text color accross all site
$color-default:             $gray-600;
$color-default2:            $gray-200;
$color-default3:            $gray-300;
$color-default4:            $gray-400;
$color-default5:            $gray-500;
$color-default6:            $gray-700;
$color-default7:            $gray-800;
$color-default8:            $gray-900;

$color-default-darker:      $color-default8;
$color-default-lighter:     $color-default2;

// Feedback colors - usually colors to be used on
// messages of success, warning or error from forms.
$color-success:             #198754; // Used on success messages
$color-info:                #0dcaf0; // Used on info messages
$color-warning:             #ffc107; // Used on warning messages
$color-danger:              #dc3545; // Used on error messages

$min-contrast-ratio:   4.5 !default;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark:      $black !default;
$color-contrast-light:     $white !default;