header{
    padding: 30px 60px 30px 60px;
    position: sticky;
    top: 0;
    z-index: 999;
    transition: 0.3s linear;

    @media only screen and (max-width: 1600px){
        padding: 30px 20px 30px 20px;
    }

    @include max-lg{
        padding: 20px 10px 20px 10px;
    }

    &.fixed-top{
        padding: 20px 60px 20px 60px;
        -webkit-box-shadow: 0px 10px 23px -15px rgba(168,168,168,1);
        -moz-box-shadow: 0px 10px 23px -15px rgba(168,168,168,1);
        box-shadow: 0px 10px 23px -15px rgba(168,168,168,1);
        background-color: $color-primary4;
        transition: 0.3s linear;

        @media only screen and (max-width: 1600px){
            padding: 20px 20px 20px 20px;
        }

        @include max-lg{
            padding: 20px 10px 20px 10px;
        }

        .logo{
            img{
                &.top-logo{
                    display: none;
                    transition: 0.3s ease;

                    @include max-lg{
                        display: block;
                    }
                }
                &.fixed-logo{
                    display: block;
                    transition: 0.3s ease;

                    @include max-lg{
                        display: none;
                    }
                }

                @include max-lg{
                    width: 100px;
                    z-index: 999999;
                    position: relative;
                }
            }
        }

        .col-2-header{
            .menu-desktop{
                nav{
                    &.navbar-expand-lg{
                        .navbar-nav{
                            .dropdown-menu {
                                top: 105px;
                            }
                        }
                    }
                }
            }
        }
    }

    .logo{
        img{
            &.top-logo{
                display: block;
                transition: 0.3s ease;
            }
            &.fixed-logo{
                display: none;
                transition: 0.3s ease;
            }

            @include max-lg{
                width: 100px;
                z-index: 999999;
                position: relative;
            }
        }
    }

    &.active-search{
        .col-2-header{
            .menu-desktop{
                display: none;
                transition: 0.3s linear;
            }

            .user-menu-btn{
                display: none;
            }

            .remove-search{
                display: block;
            }
        }

        .search{
            margin-right: 90px;
            transition: 0.3s ease;
        }
    }

    .col-2-header{
        display: inline-flex;
        align-items: center;
        justify-content: end;

        .menu-desktop{
            display: block;
            transition: 0.3s linear;

            nav{
                ul{
                    gap: 40px;

                    @include max-xxl{
                        gap: 10px;
                    }
    
                    li.nav-item{
                        a{
                            font-size: 16px;
                            color: $color-primary2;
                            font-weight: 700;
    
                            &:hover{
                                color: $color-primary;
                                text-decoration: underline;
                            }
    
                            &:focus{
                                color: $color-primary;
                                text-decoration: underline; 
                            }
                        }
                    }
                }
    
                &.navbar-expand-lg{
                    .navbar-nav{
                        .dropdown-menu {
                            position: fixed;
                            top: 165px;
                            width: 100%;
                            border-radius: 0;
                            padding: 80px 100px;
                            background-color: $color-primary;
    
                            .dropdown-subitem{
                                display: grid;
                                grid-template-columns: 30% 70%;
                                left: 0;
    
                                span{
                                    font-size: 37px; 
                                    font-weight: 300;
                                    color: $color-primary3;
                                    opacity: 0.8;
                                }
        
                                .box-subitens{
                                    .subitem-megamenu{
                                        column-gap: 0;
                                        display: grid;
                                        grid-template-columns: 50% 50%;
                                        row-gap: 0;
                                        list-style: none;
                                        padding-left: 0;
                                        align-items: center;
            
                                        li{
                                            padding: 14px 0;
                                            border-bottom: 1px solid rgba(255, 255, 255, 0.20);
                                            background-image: url('../../assets/icons/arrow-menu.svg');    
                                            background-repeat: no-repeat;
                                            background-position: 0 50%;
                                            padding-left: 30px;
                                            padding-right: 30px;
                                            margin-left: 40px;
                                            margin-right: 40px;
            
                                            a{
                                                color: $white;
                                                font-size: 1.125rem;
                                                font-weight: 500;
                                                line-height: 140%;
                                                text-decoration: none;
            
                                                &:hover{
                                                    text-decoration: underline;
                                                }
            
                                                &:focus{
                                                    text-decoration: underline;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .remove-search{
            display: none;
        }
    }

    .user-menu{
        position: absolute;

        a {
            text-decoration: none;
            font-size: 45px;
            margin-top: 10px;
            display: block;
            margin-left: 60px;

            @media only screen and (max-width: 1600px){
                margin-left: 30px;
            }
        }
    }
}

#contentmain{
    right: 0;
    left: 0;
    margin: 0 auto;
    top: 8px;
    position: absolute;
    text-align: center;
    z-index: 9999999;

    @include max-sm{
        display: none;
    }

    a{
        font-size: 12px;
        text-decoration: none;
    }
}

/*:focus {
    outline: 1px solid #272727 !important; 
}*/