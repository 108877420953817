.loba {
	display: inline-block;
	max-width: 50px;
	transition: opacity .3s ease;

	&:hover {
		opacity: 1;
	}

	img {
		width: 100%; 
	}

	@include max-md{
		display: none;
	}
}
