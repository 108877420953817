.menu-anchors {
    border-radius: 24px;
    border: 1px solid rgba(83, 179, 198, 0.40);
    background: $white;

    &.anchors-3{
        ul{
            display: grid;
            grid-template-columns: repeat(3, 33%);

            @include max-lg{
                display: block;
            }
        }
    }

    &.anchors-4{
        ul{
            display: grid;
            grid-template-columns: repeat(4, 25%);

            @include max-lg{
                display: block;
            }

            &:first-child{
                li{
                    &:first-child{
                        @include max-lg{
                            border-top: none;
                        }
                    }
                }
            }

            li{
                &:first-child{
                    @include max-lg{
                        border-top: 1px solid #BAE1E8;
                    }
                }
            }
        }
    }

    ul{
        list-style: none;
        padding: 20px;
        margin: 0;

        @include max-sm{
            padding: 0px 20px;
        }

        &:nth-child(2){
            border-top: 1px solid #BAE1E8;
            border-bottom: 1px solid #BAE1E8;

            @include max-lg{
                border-top: none;
                border-bottom: none;
            }
        }

        li{
            
            border-right: 1px solid #BAE1E8;
            transition: 0.3s linear;

            @include max-lg{
                border-right: none;
                border-bottom: 1px solid #BAE1E8;
            }

            &:last-child{
                border-right: none;

                @include max-lg{
                    border-bottom: none;
                }
            }

            a{
                text-decoration: none;
                font-size: 18px;
                font-weight: 500;
                line-height: 140%;
                color: $color-primary2;
                display: inline-flex;
                padding: 14px 30px;
                width: 100%;
                align-items: center;

                @include max-lg{
                    padding: 14px 14px 14px 0;
                }

                &:hover{
                    background-color: #D4F0F6;
                    transition: 0.3s linear;
                }

                i{
                    transform: rotate(-45deg);

                    &:before{
                        font-size: 32px;
                        width: 32px;
                        height: 32px;
                        display: inline-flex;
                        color: $color-primary3;
                    }
                }
            }
        }
    }
}