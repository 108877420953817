.box-steps{

    ul{
        list-style: none;
        padding: 0;
        display: flex;
        width: 200px;
        align-items: center;
        justify-content: center;
        margin: 0;

        li {
            display: flex;
            align-items: center;
            justify-content: center;
        
            .step{
                width: 52px;
                height: 60px;
                background-image: url('../../assets/icons/step-default.svg');
                background-repeat: no-repeat;
                background-size: contain;
                align-items: center;
                justify-content: center;
                display: flex;
                font-size: 25px;
                color: $color-primary;

                p{
                    margin-bottom: 0;
                }
            }

            &.active{
                .step{
                    background-image: url('../../assets/icons/step-hover.svg');
                }
            }

            &:after{
                content: '';
                width: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-bottom: 1px solid $color-primary3;
            }

            &:last-child{
                &:after{
                    display: none;
                }
            }
        }
    }
    
}

.box-recrutamento{
    margin: 60px 0 100px 0;
    padding: 60px;
    position: relative;
    border-radius: 24px;
    border: 1px solid $color-primary;
    background: $color-primary4;

    @include max-lg{
        padding: 90px 20px 30px 20px;
    }

    .validate-form {
        position: initial;
    }

    .form-active{

        h2{
            color: $color-primary;
            font-size: 25px;
            font-weight: 700;
            position: absolute;
            top: -16px;
            padding: 0 20px;
            left: 45px;
            background: $color-primary4;

            @include max-lg{
                width: 70%;
            }
        }

        &.step-two{
            display: none;
        }
    
        .form-group{
            margin-bottom: 30px;

            &.group-step2{   
                display: grid;
                grid-template-columns: 50% 50%;
                justify-content: center;
                align-items: center;

                @include max-lg{
                    display: block;
                }

                .form-check .form-check-input {
                    @include max-lg{
                        margin-left: 0;
                    }
                }

                .form-check{
                    margin-top: 0px;
                }

                .col2{
                    display: flex;
                    align-items: center;
                    justify-content: end;

                    @include max-lg{
                        display: block;
                    }

                    label{
                        display: flex;
                        gap: 10px;
                    }

                    input {
                        margin-top: 0;
                    }

                    .main-btn-submit {
                        padding: 20px 34px 20px 34px;
                    }
                }
            }

            .form-check{
                margin-top: 45px;
                padding-left: 0;

                &.opt-recrutamentos{
                    margin-top: 0;
                }

                &.rgpd-box{
                    a{
                        color: $color-primary;
                        text-decoration: underline;
                    }

                    input[type=checkbox]:checked{
                        background-color: $color-primary3;
                        background-image: var(--loba-form-check-bg-image);;
                    }
                }

                .checks{
                    display: flex;
                    align-items: center;
                    margin-bottom: 26px;

                    input {
                        margin-top: 0;
                    }

                    label {
                        margin-top: 0;
                        font-weight: 400;
                        margin-bottom: 0;
                        text-decoration: none;
                    }
                }
    
                input {
                    padding: 0;
                    border-radius: 5px;
                    margin-top: 15px;
                }
    
                label {
                    margin-left: 10px;
                    margin-top: 15px;
    
                    &.error{
                        margin: 0;
                        margin-left: 10px;
                        text-decoration: none;
                    }
                }
            }
    
            label {
                color: #014150;
                font-family: Montserrat;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 160%;
                margin-left: 20px;
                margin-bottom: 6px;
    
                &.error{
                    font-weight: 400;
                    font-size: 13px;
                    color: red;
                }
    
                &.form-check-label {
                    text-decoration: none;
                    font-weight: 400;
                }
            }
    
            input{
                padding: 10px 8px 10px 20px;
                border-radius: 24px;
                border: 1px solid $color-primary3;
                background: rgba(255, 255, 255, 0.50);
            }

            select{
                padding: 10px 8px 10px 20px;
                border-radius: 24px;
                border: 1px solid $color-primary3;
                background: rgba(255, 255, 255, 0.50);
            }
            
            textarea{
                padding: 10px 8px 10px 20px;
                border-radius: 24px;
                border: 1px solid $color-primary3;
                background: rgba(255, 255, 255, 0.50);
            }

            &.group-cv{
                display: grid;
                width: 100%;
                gap: 20px;
                grid-template-columns: 255px auto;

                @include max-lg{
                    gap: 0;
                    grid-template-columns: 100%;
                }

                .bt-label-cv{
                    display: inline-flex;
                    border: 1px solid rgba(1, 65, 80, 0.40);
                    border-radius: 50px; 
                    padding: 10px 20px;
                    font-weight: 700;
                    margin-left: 0;
                    align-items: center;
                    gap: 10px;
                    cursor: pointer;
                    transition: 0.2s linear;
    
                    &:hover{
                        background: #BAE1E8;
                        transition: 0.2s linear;
                    }
    
                    i{
                        font-size: 30px;
                        width: 30px;
                        height: 30px;
                        display: inline-flex;
                    }
                }

                input {
                    padding: 10px 14px;
                    border-radius: 0;
                    border: none;
                    background: transparent;
                    font-weight: 400;
                }
            }
    
            .main-btn-submit{
                padding: 20px 24px 20px 34px;
                border-radius: 100px;
                background: $color-primary;
                font-size: 14px;
                font-weight: 700;
                display: inline-flex;
                float: right;
                transition: 0.2s linear;
                border: none;

                @include max-lg{
                    float: left;
                    width: 100%;
                    justify-content: space-between;
                    margin-top: 30px;
                }
    
                &:hover{
                    background: $color-primary3;
                    transition: 0.2s linear;
                }
    
                i {
                    font-size: 30px;
                    width: 30px;
                    height: 30px;
                    margin-top: -9px;
                    margin-left: 8px;            
    
                    &:before{
                        color: $white;
                    }
                }
            } 

            .bts-nobutton-back{
                color: $color-primary;
                display: inline-flex;
                text-decoration: none;
                justify-content: center;
                align-items: center;

                i {
                    font-size: 30px;
                    width: 30px;
                    display: block;
                    height: 30px;
                    text-decoration: none;
                    margin-right: 5px;
                    transform: rotate(180deg);
                }
            }

            .bts-nobutton{
                padding: 20px 24px 20px 34px;
                border-radius: 100px;
                background: $color-primary;
                font-size: 14px;
                font-weight: 700;
                display: inline-flex;
                float: right;
                transition: 0.2s linear;
                border: none;
                color: $white;
                text-decoration: none;
                cursor: pointer;

                @include max-lg{
                    float: left;
                    width: 100%;
                    justify-content: space-between;
                }
    
                &:hover{
                    background: $color-primary3;
                    transition: 0.2s linear;
                }
    
                i {
                    font-size: 30px;
                    width: 30px;
                    height: 30px;
                    margin-top: -9px;
                    margin-left: 8px;            
    
                    &:before{
                        color: $white;
                    }
                }
            } 
        }
    }

    .form-thankyou{
        padding: 0 300px;

        h2{
            font-size: 25px;
            font-weight: 700;
            line-height: 160%;
            color: $color-primary;
            margin: 30px 0 0 0;
        }
    }
}

.required-info{
    color: $color-primary;
    font-size: 13px;
    display: block;
    font-weight: 500;
    margin: 0 0 30px 0;
}