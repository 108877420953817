input[type="number"] {
	-moz-appearance: textfield;
}

textarea, 
textarea.form-control {
	min-height: $inputTextareaHeight;
	max-width: 100%;
}

.form-check-input-inverted {
	$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-bg-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/></svg>");
	$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-bg-color}'/></svg>");

	&:checked {
		border-color: $input-border-color;
		background-color: $form-check-input-checked-color;

		&[type="checkbox"] {
			@if $enable-gradients {
				--#{$prefix}form-check-bg-image: #{escape-svg($form-check-input-checked-bg-image)},
				var(--#{$prefix}gradient);
			}

			@else {
				--#{$prefix}form-check-bg-image: #{escape-svg($form-check-input-checked-bg-image)};
			}
		}

		&[type="radio"] {
			@if $enable-gradients {
				--#{$prefix}form-check-bg-image: #{escape-svg($form-check-radio-checked-bg-image)},
				var(--#{$prefix}gradient);
			}

			@else {
				--#{$prefix}form-check-bg-image: #{escape-svg($form-check-radio-checked-bg-image)};
			}
		}
	}
}

.form-group{
	input{
		padding: 20px 30px;
		border: none;
		border-radius: 100px;
		font-size: 14px;
		font-weight: 700;

		&::placeholder{
			opacity: 1;
			color: $color-primary;
		}

		&[type="checkbox"]{
			width: 20px;
			height: 20px;
			padding: 0;

			&:checked{
				background-color: $color-primary3;
			}

			&:focus{
				outline: auto;
			}
		}
	}

	.form-check{
		margin-top: 25px;

		@include max-md{
			margin-top: 45px;
		}

		label{
			margin: 0;
			color: $white;
			font-size: $font-size-base;
			margin-left: 10px;

			a{
				color: $white;
			}
		}

		.invalid-feedback {
			color: $white;
		}
	}
}

.search{
	margin-left: 80px;
	transition: 0.3s ease;

	@media only screen and (max-width: 1600px){
		margin-left: 30px;
	}

	.input-group{
		input{
			border-radius: 100px !important;
			padding: 20px 60px 20px 24px;
			color: $color-primary;
			font-weight: 700;
			border-color: $color-primary2;
			width: 195px;

			&::placeholder{
				opacity: 1;
				color: $color-primary2;

				@include max-lg{
					opacity: 0;
				}
			}

            &::-webkit-search-cancel-button {
                display: none;
            }
		}

		button{
			position: absolute;
			top: 8px;
			font-size: 35px;
			right: 15px;
			z-index: 9;
			padding: 0;
			border: none;
			background: transparent;
		}
	}
}