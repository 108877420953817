body {
	background-color: $color-primary4;
	overflow-x: hidden;

	&.modal-open {
		overflow-y: hidden !important;
	}

	&.back-light{
		background-color: $white;
	}
}

#load{
	width: 100%;
	height: 100vh;
	position: relative;
}

#loading-container{
  	position: absolute;
	width: 100%;
	height: 100%;
	scale: 1.8;
}

.grecaptcha-badge {
	display: none !important;
}


//Hacks

p {
	color: $color-primary5;
	line-height: 160%;
}

.fw-700,
.fw-700 h1,
.fw-700 h2,
.fw-700 h3,
.fw-700 h4,
.fw-700 h5,
.fw-700 h6 {
	font-weight: 700;
}

.p-large p,
.p-large {
	font-size: 1.250rem;
	line-height: 160%;

	@include max-lg{
		font-size: 1rem;
	}
}

.p-large-color p,
.p-large-color {
	font-size: 1.250rem;
	color: $color-primary3;
	font-weight: 700;
	line-height: 160%;

	@include max-lg{
		font-size: 1rem;
	}
}

.p-normal p,
.p-normal {
	line-height: 160%;
}

.p-small p,
.p-small {
	font-size: 0.812rem;
}

.p-extra-small p,
.p-extra-small {
	font-size: 0.625rem;
}

.fc-white,
.fc-white p,
.fc-white h1,
.fc-white h2,
.fc-white h3,
.fc-white h4,
.fc-white h5,
.fc-white a {
	color: $white;
}

.fc-green,
.fc-green p,
.fc-green h1,
.fc-green h2,
.fc-green h3,
.fc-green h4,
.fc-green h5,
.fc-green a {
	color: $color-primary3;
}

.fc-green-dark,
.fc-green-dark p,
.fc-green-dark h1,
.fc-green-dark h2,
.fc-green-dark h3,
.fc-green-dark h4,
.fc-green-dark h5,
.fc-green-dark a {
	color: $color-primary2;
}

.back-white {
	background-color: $white;
}

.pt-30 {
	padding-top: 30px;
}

.pb-30 {
	padding-bottom: 30px;
}

.pt-50 {
	padding-top: 50px;
}

.pb-50 {
	padding-bottom: 50px;
}

.pt-90 {
	padding-top: 90px;
}

.pb-90 {
	padding-bottom: 90px;
}

.pt-100 {
	padding-top: 100px;

	@include max-md{
		padding-top: 60px;
	}
}

.pb-100 {
	padding-bottom: 100px;

	@include max-md{
		padding-bottom: 60px;
	}
}

.pt-120 {
	padding-top: 120px;
}

.pb-120 {
	padding-bottom: 120px;
}

.pt-150 {
	padding-top: 150px;
}

.pb-150 {
	padding-bottom: 150px;
}

.pt-170 {
	padding-top: 170px;
}

.pb-170 {
	padding-bottom: 170px;
}

.pt-200 {
	padding-top: 200px;

	@include max-md{
		padding-top: 80px;
	}
}

.pb-200 {
	padding-bottom: 200px;

	@include max-md{
		padding-bottom: 80px;
	}
}

.underline-title {
	&:after {
		content: '';
		width: 64px;
		height: 3px;
		background: #205967;
		display: block;
		margin: 12px 0 0 0;
	}
}

.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm,
.container {
	max-width: 1410px;

	@include max-lg{
		padding: 0 20px;
	}
}

.hidden-desktop-menu{
	display: none !important;

	@include max-xl{
		display: block !important;
	}
}

.hidden-mobile-menu{
	display: inline-flex !important;

	@include max-xl{
		display: none !important;
	}
}

body{
	#pojo-a11y-toolbar .pojo-a11y-toolbar-toggle a {
		background-color: #cc9e53;
		color: #ffffff;
		width: 46px;
		height: 46px;
		display: flex;
		align-items: center;
		justify-content: center;
		/*position: fixed;
		bottom: 20px;
		right: 30px;*/
		border-radius: 5px;
	}
}

body.pojo-a11y-light-background.edit-acc,
body.pojo-a11y-light-background.edit-acc *:not(#pojo-a11y-toolbar):not(.pojo-a11y-toolbar-link) {
    
	footer {
		.col-2-footer {
			.redes-sociais {
				.box {
					a {
						&.icon-facebook {
							background-image: url('../../assets/icons/facebook.svg') !important;
	
							&:hover, &:focus {
								background-image: url('../../assets/icons/facebook-hover.svg') !important;
							}
						}
	
						&.icon-youtube {
							background-image: url('../../assets/icons/youtube.svg') !important;
	
							&:hover, &:focus {
								background-image: url('../../assets/icons/youtube-hover.svg') !important;
							}
						}
	
						&.icon-twitter {
							background-image: url('../../assets/icons/twitter.svg') !important;
	
							&:hover, &:focus {
								background-image: url('../../assets/icons/twitter-hover.svg') !important;
							}
						}
	
						&.icon-linkedin {
							background-image: url('../../assets/icons/linkedin.svg') !important;
	
							&:hover, &:focus {
								background-image: url('../../assets/icons/linkedin-hover.svg') !important;
							}
						}
	
						&.icon-instagram {
							background-image: url('../../assets/icons/instagram.svg') !important;
	
							&:hover, &:focus {
								background-image: url('../../assets/icons/instagram-hover.svg') !important;
							}
						}
					}
				}
			}

			.buttons-intranet a i.ico-intranet {
				background-image: url(../../assets/icons/icon-intranet.svg) !important;
			}
		}
	}

	.form-group input {
		border: 1px solid;
	}

	.btn.btn-form-1 {
		border: 1px solid;
	}

	.numbers-appc {
		.path-arrow {
			img {
				width: 100%;
			}
		}
	
		.logo-appc {
			.vector-mobile{
				@include max-lg{
					background: url('../../assets/img/vector/vector-mobile.svg') !important;
				}
			}
			
			.vector-1 {
				background: url('../../assets/img/vector/Vector-1.svg') !important;
	
				.marks {
					background: url('../../assets/img/vector/mark.svg') !important;
				}
			}
	
			.vector-2 {
				background: url('../../assets/img/vector/Vector-2.svg') !important;
	
				.marks {
					background: url('../../assets/img/vector/mark.svg') !important;
				}
			}
	
			.vector-3 {
				background: url('../../assets/img/vector/Vector-3.svg') !important;
	
				.marks {
					background: url('../../assets/img/vector/mark.svg') !important;
				}
			}
	
			.vector-4 {
				background: url('../../assets/img/vector/Vector-4.svg') !important;
	
				.marks {
					background: url('../../assets/img/vector/mark.svg') !important;
				}
			}
	
			.vector-5 {
				background: url('../../assets/img/vector/Vector-5.svg') !important;
	
				.marks {
					background: url('../../assets/img/vector/mark.svg') !important;
				}
			}
	
			.vector-6 {
				background: url('../../assets/img/vector/Vector-6.svg') !important;
	
				.marks {
					background: url('../../assets/img/vector/mark.svg') !important;
				}
			}
		}
	}

	.btn.btn-read-more {
		border: 1px solid;
	}

	.wpcf7 input.wpcf7-submit {
		border: 1px solid;
	}
}