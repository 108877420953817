/* Barra cookies */


.cookies-wrapper {
	--#{$prefix}modal-footer-bg: #{$cookiesFooterBackground};
	--#{$prefix}modal-bg: #{$cookiesBoxBackground};
	--#{$prefix}modal-width: #{$cookiesBoxMaxWidth};

	font-size: $cookiesTextBaseSize;
	button {
		margin-bottom: .625rem;
		border-radius: 100px;
		font-weight: 600;
		padding: 18px 25px !important;
		margin: 20px 0 20px 0;

		@include max-sm {
			display: block;
			width: 100%;
		}
	}

	.modal-title{
		font-size: 22px;
		margin: 20px 0 0 0;
	}

	.form-group .form-check{
		margin-top: 0;

		label {
			color: $color-primary;
			line-height: 130%;
			margin: 0 0 7px 0;
		}

		input{
			--loba-form-switch-bg: url('../../assets/img/check.svg');
			border-radius: 0;

			&:checked{
				--loba-form-switch-bg: url('../../assets/img/check-active.svg');
				border-radius: 5px;
			}
		}
	} 
}

.cookies-bar-message {
	display: block;
	margin-bottom: $grid-gutter-width;
	font-size: 1em;
	line-height: 1.6;
}

.cookies-settings-link {
	@include min-sm {
		text-align: right;
	}
}

.cookies-settings {
	display: none;
}

.cookies-settings-cancel {
	@include min-sm {
		text-align: right;
	}
}

.cookie-info {
	display: block;
	line-height: 1rem;
}

.cookies-bar-know-more {
	color: $color-default-darker;
	transition: opacity 0.3s ease;

	&:hover,&:focus{
		opacity: .6;
	}
}

.cookies-message {
	p {
		display: none;
	}

	b {
		display: block;
		margin-bottom: .625rem;
		border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	}
}

/* Barra cookies END */


.cookies__wrapper {
	#cookiesTitle {
		font-size: 24px;
		font-weight: 700;
	}

	.cookies__box.modal-dialog.modal-dialog-centered {
		max-width: 700px;
	}

	button.btn.btn-link.cookies__settings-link {
		padding: 0;
		border: none;
	}

	button.btn.btn-primary.btn-block.py-2.cookies__accept,
	button.btn.btn-primary.btn-block.py-2.cookies__deny,
	button.btn.btn-primary.py-2.cookies__submit,
	button.btn.btn-outline-default.py-2.cookies__cancel {
		height: 55px;
		border-radius: 100px;
	}

	.accordion-button::before {
		flex-shrink: 0;
		width: var(--loba-accordion-btn-icon-width);
		height: var(--loba-accordion-btn-icon-width);
		margin-left: 0;
		content: "";
		background-image: var(--loba-accordion-btn-icon);
		background-repeat: no-repeat;
		background-size: var(--loba-accordion-btn-icon-width);
		transition: var(--loba-accordion-btn-icon-transition);
		margin-right: 10px;
	}

	a.cookies__know-more.text-decoration-none {
		font-weight: 600;
	}

	.accordion-button::after{
		display: none;
	}

	.form-check.form-switch.form-control-lg {
		position: absolute;
		right: 0;
		top: 10px;
		z-index: 9;
	}

	button:focus:not(:focus-visible),
	button:focus {
		outline: auto;
	}
}