.hero-homepage {

    @include max-md{
        padding-right: 20px;
    }

    h1 {
        margin: 0 0 100px 0;
        text-align: center;

        @include max-lg{
            text-align: left;
            margin: 0 0 50px 0;
        }
    }

    img {
        width: 100%;
        border-radius: 0px 300px 300px 0px;
        height: 60vh;
        object-fit: cover;

        @include max-md{
            height: 35vh;
        }
    }

    .indicator {
        position: absolute;
        right: 10vw;
        top: 0;

        @media only screen and (max-width: 1600px) {
            right: 40px;
        }

        @include max-lg{
            display: none;
        }

        img {
            width: auto;
            border-radius: 0;
        }
    }


    .col-2-hero {
        padding: 0 19vw 0 145px;

        @media only screen and (max-width: 1600px) {
            padding: 0 130px 0 70px;
        }

        @include max-lg{
            padding: 50px 20px 0 20px;
        }

        p {
            margin: 40px 0;
        }

        .read-more{
            .btn.btn-read-more {
                width: auto;
            }
        }

        h2{
            font-size: 3.0625rem;
            font-weight: 400; 

            @include max-md{
                font-size: 2.125rem;
            }
        }
    }

    .swiperHeroHome{
        border-radius: 0px 300px 300px 0px;
        padding-bottom: 50px;

        .swiper-pagination-bullet{
            background: $color-primary2;
            width: 10px;
            height: 10px;

            &.swiper-pagination-bullet-active{
                color: $white;
            }
        }
    }
}

.destaques-homepage {
    padding: 0 0 100px 0;

    h2{
        font-size: 2.3625rem;
        font-weight: 700;

        @include max-md{
            font-size: 2.125rem;
        }
    }

    @include max-md{
        padding: 60px 10px
    }

    .swiper {
        width: 100%;
        height: 770px;
        overflow-x: inherit;

        @include max-xl{
            height: auto;
            overflow-x: hidden; 
        }

        .swiper-slide {
            display: grid;
            justify-content: center;
            grid-template-columns: auto 625px;
            column-gap: 80px;
            row-gap: 0;

            @include max-xl{
                display: block;
                height: 100% !important;
                margin: 0 0 70px 0;
            }

            h3 {
                font-weight: 700;
                font-size: 1.50rem; 
            }

            img {
                width: 100%;
                height: 330px;
                object-fit: cover;
                border-radius: 24px;
            }

            p {
                width: 100%;
                height: 100px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 30px 0;
            }

            .btn.btn-read-more-outline {
                width: auto;
                margin-bottom: 30px;
                margin-top: 15px;
            }
        }

        .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-vertical {
            right: -100px;

            @media only screen and (max-width: 1600px) {
                right: -50px;
            }

            @include max-xl{
                display: none;
            }

            &:before {
                content: '';
                width: 1px;
                height: 120px;
                background-color: $color-primary3;
                display: block;
                margin: -30px auto 30px auto;
            }

            span.swiper-pagination-bullet {
                background: $color-primary3;
                opacity: 1;
                width: 10px;
                height: 10px;

                &.swiper-pagination-bullet-active {
                    background: $color-primary;
                }
            }
        }
    }

    .button-more {
        margin-top: 40px;

        @include max-xl{
            margin-bottom: 50px;
        }

        @include max-lg{
            margin-top: 0;
        }

        .btn.btn-read-more {
            background-color: #014150;
            border-radius: 100px;
            padding: 6px 8px 5px 23px;
            color: #fff;
            font-weight: 700;
            font-size: 14px;
            display: inline-flex;
            align-items: center;
            transition: 0.2s linear;

            @include max-lg{
                width: auto;
            }

            &:hover {
                background-color: $color-primary3;
                transition: 0.2s linear;
            }
        }
    }

    .ml-negative {
        margin-left: -150px;
        margin-top: -5px;

        @media only screen and (max-width: 1715px) {
            margin-left: 0;
            margin-top: -125px;
        }

        @include max-lg{
            margin-left: 0;
            margin-top: 0;
            margin-bottom: 50px;
        }
    }
}

.servicos-homepage {

    h2 {
        margin-left: 150px;
        font-size: 3.0625rem;
        font-weight: 700; 
    
        @include max-xl{
            margin-left: 10px;
        }

        @include max-md{
            font-size: 2.125rem;
        }
    }

    .swiper {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 60px;
        padding-right: 50px;
        padding-left: 80px;
        overflow: initial;
        padding-bottom: 120px;

        @media only screen and (max-width: 1200px) {
            overflow: hidden;
        }

        @include max-xl{
            padding-left: 20px;
        }

        .swiper-slide {
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 0.3s linear;
            border: none;
            border-radius: 24px;

            @include max-xl{
                margin-bottom: 20px;
            }

            &:nth-child(2n) {
                margin-top: -40px !important;

                @include max-xl{
                    margin-top: 0 !important;
                }
            }

            &:nth-child(2n+1) {
                margin-top: 40px !important;

                @include max-xl{
                    margin-top: 0 !important;
                }
            }

            &:hover {
                transform: scale(1.03);
                transition: 0.3s linear;
                border-radius: 24px;
            }

            &:focus {
                transform: scale(1.03);
                transition: 0.3s linear;
                border-radius: 24px;
            }

            a {
                text-decoration: none;
                width: 100%;
                min-height: 300px;
                padding: 30px;
                border-radius: 24px;
                border: none;

                &.yellow{
                    background-color: #F9F7A0;
                }

                &.purple{
                    background-color: #E7EAF6;
                }

                &.greenlight{
                    background-color: #8ED7CE;
                }

                &.green{
                    background-color: #9DF0AC;
                }

                &.brown{
                    background-color: #E5DDDA;
                }

                &.pink{
                    background-color: #FEE3CF;
                }

                &.blue{
                    background-color: #C1EAF2;
                }

                &.bluelight{
                    background-color: #C1EAF2;
                }

                &:hover {
                    .divisor {
                        .line {
                            width: 235px;
                            transition: 0.3s ease;

                            @include max-sm{
                                width: 155px;
                            }
                        }
                    }
                }

                &:focus {
                    .divisor {
                        .line {
                            width: 235px;
                            transition: 0.3s ease;

                            @include max-sm{
                                width: 155px;
                            }
                        }
                    }
                }

                .divisor {
                    margin: 15px 0 5px 0;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;

                    .line {
                        height: 1px;
                        width: 155px;
                        display: block;
                        background: $color-primary;
                        transition: 0.3s ease;

                        @include max-sm{
                            width: 130px;
                        }
                    }

                    i {

                        width: 30px;
                        height: 30px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &:before {
                            font-size: 40px;
                            width: 40px;
                            height: 40px;
                            display: block;
                            color: $color-primary;
                        }
                    }
                }


                .texto {
                    position: absolute;
                    bottom: 30px;
                }

                h3 {
                    font-size: 21px;
                    color: $color-primary;
                    text-decoration: none;
                    font-weight: 700;
                    line-height: 160%;
                    padding-right: 30px;

                    @include max-sm{
                        font-size: 18px;
                        line-height: 145%;
                        padding-right: 27px;
                    }
                }

                img{
                    width: 100px;
                }
            }
        }

        .pagination {
            .line {
                width: 120px;
                height: 1px;
                background: $color-primary3;
                position: absolute;
                right: 228px;
                bottom: 30px;
            }

            .swiper-button-prev {
                left: inherit;
                right: 140px;
                bottom: 0px;
                top: inherit;
                width: 60px;
                height: 60px;
                background: $color-primary;
                border-radius: 100px;
                transition: 0.3s linear;

                &.swiper-button-disabled {
                    width: 40px;
                    height: 40px;
                    bottom: 10px;
                    right: 160px;
                    transition: 0.3s linear;
                }

                i {
                    font-size: 40px;
                    transform: rotate(-180deg);

                    &:before {
                        color: $white;
                        display: inherit;
                    }
                }

                &:after {
                    display: none;
                }
            }

            .swiper-button-next {
                right: 80px;
                bottom: 0px;
                top: inherit;
                width: 60px;
                height: 60px;
                background: $color-primary;
                border-radius: 100px;
                transition: 0.3s linear;

                &.swiper-button-disabled {
                    width: 40px;
                    height: 40px;
                    bottom: 10px;
                    right: 80px;
                    transition: 0.3s linear;
                }

                i {
                    font-size: 40px;

                    &:before {
                        color: $white;
                        display: inherit;
                    }
                }

                &:after {
                    display: none;
                }
            }
        }
    }
}

.numbers-appc {
    .path-arrow {
        img {
            width: 100%;
        }
    }

    .logo-appc {
        text-align: center;
        position: relative;

        img {
            margin-top: -50px;
            position: relative;
            z-index: 9;

            @include max-sm{
                width: 180px;
            }
        }

        .vector-mobile{
            display: none;

            @include max-xl{
                display: block;
                width: 126px;
                height: 530px;
                background: url('../../assets/img/vector/vector-mobile.svg');
                margin: 0 auto;
                padding-left: 243px;
                background-repeat: no-repeat;
            }
        }

        .vectors-forms{
            @include max-xl{
                margin-left: calc(50% - 96px);
                position: absolute;
                bottom: -20px;
            }
        }

        .vector-1 {
            width: 209px;
            height: 46px;
            background: url('../../assets/img/vector/Vector-1.svg');
            position: absolute;
            top: 33px;
            left: 358px;

            @include max-xl{
                background: none;
                position: relative;
                width: 100%;
                left: 0;
                height: auto;
                top: 0;
            }

            .marks {
                background: url('../../assets/img/vector/mark.svg');
                width: 17.298px;
                height: 20px;
                background-size: contain;
                background-repeat: no-repeat;
                position: absolute;
                left: -16px;
                top: -8px;

                @include max-xl{ 
                    left: -30px;
                    top: 5px;
                }
            }

            .texto {
                position: absolute;
                right: 245px;
                top: -25px;

                @include max-xl{
                    right: 0;
                    position: relative;
                    top: 0;
                }
            }
        }

        .vector-2 {
            width: 209px;
            height: 35px;
            background: url('../../assets/img/vector/Vector-2.svg');
            position: absolute;
            top: 138px;
            left: 358px;

            @include max-xl{
                background: none;
                position: relative;
                width: 100%;
                left: 0;
                height: auto;
                top: 0;
            }

            .marks {
                background: url('../../assets/img/vector/mark.svg');
                width: 17.298px;
                height: 20px;
                background-size: contain;
                background-repeat: no-repeat;
                position: absolute;
                left: -15px;
                bottom: -9px;

                @include max-xl{ 
                    left: -30px;
                    top: 5px;
                    bottom: inherit;
                }
            }

            .texto {
                position: absolute;
                right: 245px;
                top: -5px;

                @include max-xl{
                    right: 0;
                    position: relative;
                    top: 0;
                }
            }
        }

        .vector-3 {
            width: 253px;
            height: 106px;
            background: url('../../assets/img/vector/Vector-3.svg');
            position: absolute;
            bottom: -50px;
            left: 352px;

            @include max-xl{
                background: none;
                position: relative;
                width: 100%;
                right: inherit;
                left: 0;
                height: auto;
                top: 0;
            }

            .marks {
                background: url('../../assets/img/vector/mark.svg');
                width: 17.298px;
                height: 20px;
                background-size: contain;
                background-repeat: no-repeat;
                position: absolute;
                left: -15px;
                bottom: -10px;

                @include max-xl{ 
                    left: -30px;
                    top: 5px;
                    bottom: inherit;
                }
            }

            .texto {
                position: absolute;
                right: 285px;
                top: 65px;

                @include max-xl{
                    right: 0;
                    position: relative;
                    top: 0;
                }
            }
        }

        .vector-4 {
            width: 211px;
            height: 63px;
            background: url('../../assets/img/vector/Vector-4.svg');
            position: absolute;
            top: 40px;
            right: 355px;

            @include max-xl{
                background: none;
                position: relative;
                width: 100%;
                right: inherit;
                left: 0;
                height: auto;
                top: 0;
            }

            .marks {
                background: url('../../assets/img/vector/mark.svg');
                width: 17.298px;
                height: 20px;
                background-size: contain;
                background-repeat: no-repeat;
                position: absolute;
                right: -15px;
                top: -9px;

                @include max-xl{ 
                    left: -30px;
                    top: 5px;
                    right: inherit;
                }
            }

            .texto {
                position: absolute;
                left: 245px;
                top: -30px;

                @include max-xl{
                    left: 0;
                    position: relative;
                    top: 0;
                }
            }
        }

        .vector-5 {
            width: 209px;
            height: 35px;
            background: url('../../assets/img/vector/Vector-5.svg');
            position: absolute;
            top: 137px;
            right: 357px;

            @include max-xl{
                background: none;
                position: relative;
                width: 100%;
                right: inherit;
                left: 0;
                height: auto;
                top: 0;
            }

            .marks {
                background: url('../../assets/img/vector/mark.svg');
                width: 17.298px;
                height: 20px;
                background-size: contain;
                background-repeat: no-repeat;
                position: absolute;
                right: -17px;
                bottom: -10px;

                @include max-xl{ 
                    left: -30px;
                    top: 5px;
                    right: inherit;
                    bottom: inherit;
                }
            }

            .texto {
                position: absolute;
                left: 245px;
                top: 0px;

                @include max-xl{
                    left: 0;
                    position: relative;
                    top: 0;
                }
            }
        }

        .vector-6 {
            width: 253px;
            height: 108px;
            background: url('../../assets/img/vector/Vector-6.svg');
            position: absolute;
            bottom: -52px;
            right: 352px;

            @include max-xl{
                background: none;
                position: relative;
                width: 100%;
                right: inherit;
                left: 0;
                height: auto;
                top: 0;
            }

            .marks {
                background: url('../../assets/img/vector/mark.svg');
                width: 17.298px;
                height: 20px;
                background-size: contain;
                background-repeat: no-repeat;
                position: absolute;
                right: -15px;
                bottom: -9px;

                @include max-xl{ 
                    left: -30px;
                    top: 5px;
                    right: inherit;
                    bottom: inherit;
                }
            }

            .texto {
                position: absolute;
                left: 285px;
                top: 70px;

                @include max-xl{
                    left: 0;
                    position: relative;
                    top: 0;
                }
            }
        }

        .texto {

            &.right {
                .numbers {
                    text-align: right;

                    @include max-xl{
                        text-align: left;
                    }
                }

                .descript {
                    text-align: right;

                    @include max-xl{
                        text-align: left;
                    }
                }

                p {
                    text-align: right;

                    @include max-xl{
                        text-align: left;
                    }
                }
            }

            &.left {
                .numbers {
                    text-align: left;
                }

                .descript {
                    text-align: left;
                }

                p {
                    text-align: left;
                }
            }

            .numbers {
                font-size: 1.9375rem;
                font-weight: 700;
                line-height: normal;
                margin: 0;

                @include max-xl{
                    font-size: 1.625rem;
                    margin: 10px 0 0 0;
                }
            }

            .descript {
                font-size: 1.25rem;
                font-weight: 700;
                line-height: 160%;
                color: $color-primary2;
                margin: 0;

                @include max-xl{
                    font-size: 1.125rem;
                }
            }

            p {
                color: $color-primary2;
                font-weight: 400;
                font-size: $font-size-base;
                margin: 0;

                @include max-xl{
                    font-size: 0.875rem;
                }
            }
        }
    }
}

.bt-search-mobile {
    display: flex;
    position: absolute;
    right: 75px;
    background: #fff;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    border: 1px solid $color-primary3;
    justify-content: center;
    align-items: center;
    top: 35px;

    i {
        font-size: 30px;
        position: relative;
        top: 1px;
        color: $color-primary2;
    }
}

#modal-search-mobile {
    background: $color-primary4;
    position: fixed;
    left: 0;
    width: 100%;
    top: -200px;
    padding: 30px 20px;
    z-index: 999999;
    -webkit-box-shadow: 0px 10px 23px -15px rgba(168,168,168,1);
    -moz-box-shadow: 0px 10px 23px -15px rgba(168,168,168,1);
    box-shadow: 0px 10px 23px -15px rgba(168,168,168,1);
    transition: all .6s ease;

    form {
        width: calc(100% - 50px);

        input{
            padding: 14px 20px;
            border-radius: 100px;
            font-weight: 700;
            font-size: 14px;
            color: $color-primary2;
    
            &::placeholder{
                opacity: 1;
                color: $color-primary2;
            }

            &::-webkit-search-cancel-button {
                display: none;
            }
        }
    }
    
    button{
        background: none;
        border: none;
        font-size: 40px;
        position: absolute;
        right: 10px;
        top: -3px;
        z-index: 5;

        &.bt-close-search-mobile{
            top: 28px;
        }
    }
}