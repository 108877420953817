// ===== DEBUG MODE ===== //
// 0 - not on debug mode
// 1 - highlights with red border elements without required attributes
// 2 - highlights with red border elements without required attributes or empty

$debugMode: 0;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret:                true !default;
$enable-rounded:              true !default;
$enable-shadows:              false !default;
$enable-gradients:            false !default;
$enable-transitions:          true !default;
$enable-reduced-motion:       true !default;
$enable-smooth-scroll:        true !default;
$enable-grid-classes:         true !default;
$enable-container-classes:    true !default;
$enable-cssgrid:              false !default;
$enable-button-pointers:      true !default;
$enable-rfs:                  true !default;
$enable-validation-icons:     true !default;
$enable-negative-margins:     false !default;
$enable-deprecation-messages: true !default;
$enable-important-utilities:  true !default;

// Input shadows
$enable-focus-shadow:         false;

$enable-dark-mode:            true !default;
$color-mode-type:             data !default; // `data` or `media-query`

// Prefix for :root CSS variables

$prefix:                      loba- !default;

// ===== GRID AND VIEWPORTS ===== //
$grid-columns:                12 !default;
$grid-gutter-width:           1.5rem !default;
$grid-row-columns:            6 !default;

$screen-sm:                   576px;
$screen-md:                   768px;
$screen-lg:                   992px;
$screen-xl:                   1200px;
$screen-xxl:                  1400px; // will be the maximum width of the site

$grid-breakpoints: (
  xs: 0,
  sm: $screen-sm,
  md: $screen-md,
  lg: $screen-lg,
  xl: $screen-xl,
  xxl: $screen-xxl
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
) !default;

// Container padding
$container-padding-x:       $grid-gutter-width !default;

$spacer:                    1rem !default;
