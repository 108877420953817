.orgaos-sociais {
    padding-top: 40px;
    padding-bottom: 40px;

    .box-orgaos-sociais{    
        margin: 0 0 60px 0;
        padding-top: 30px;

        .hed-box{
            position: relative;
            display: inline-flex;
            width: 100%;
            align-items: center;
            margin: 0 0 60px 0;

            h2{
                white-space: pre;
                background: $color-primary4;
                padding-right: 40px;
                margin: 0;
                font-size: 2.4375rem;
                font-weight: 700;

                @include max-md{
                    font-size: 1.5625rem;
                }
            }

            .line{
                width: 100%;
                height: 1px;
                background: $color-primary2;
            }
        }

        ul{
            list-style: none;
            padding: 0;
            display: grid;
            grid-template-columns: repeat(5, 210px);
            gap: 40px;
            margin-right: 200px;

            @include max-lg{
                grid-template-columns: repeat(3, 33%);
                margin-right: 0;
                grid-row-gap: 10px;
                grid-column-gap: 5px;
            }

            @include max-sm{
                grid-template-columns: repeat(2, 50%);
            }
            
            li{
                border-radius: 24px;
                border: 1px solid rgba(83, 179, 198, 0.40);
                background: #FFF;
                padding: 20px;
                transition: 0.2s linear;

                &:hover{
                    background-color: #D4F0F6;
                    transition: 0.2s linear;
                }

                img{
                    margin: 0 0 10px 0;

                    @include max-sm{
                        width: 100px;
                    }
                }

                p{
                    margin: 0;
                    color: #272727;
                    
                    &.name{
                        color: $color-primary2;
                        font-size: $font-size-base;
                        line-height: 160%;
                        font-weight: 700;
                    }

                    &.function{
                        font-size: 13px;
                        font-weight: 700;
                    }

                    &.date{
                        font-size: 10px;
                        font-weight: 400;
                    }
                }

                h3{
                    color: $color-primary2;
                    font-size: $font-size-base;
                    line-height: 160%;
                    font-weight: 700;
                    margin: 0;
                }
            }
        }
    }
}