.search-result-page {
    .menu-tabs{
        list-style: none;
        padding: 0;

        button{
            border-radius: 6px;
            border: 1px solid #BAE1E8;
            background: #D4F0F6;
            padding: 10px 20px;
            margin-bottom: 12px;
            transition: 0.2s linear;
            font-size: 14px;
            font-weight: 700;
            color: $color-primary;
            text-decoration: none;
            display: inline-flex;
            width: 100%;
            justify-content: space-between;

            i.icon-arrow-down {
                transform: rotate(-90deg);
                font-size: 23px;
                width: 23px;
                height: 20px;
                color: $color-primary2;
            }

            &:hover{
                background: $white;
                transition: 0.2s linear;
            }

            &.active{
                background: $white;
                color: $color-primary2;
            }
        }
    }

    .grid-result-tabs{
        margin-top: 90px;

        .box-result{
            margin: 0 0 40px 0;
            padding: 0 20px;      
            
            .logo{
                img{
                    width: 100px;
                    height: 100px;
                    object-fit: cover;
                    border-radius: 6px;
                }
            }
    
            a{
                display: inline-flex;
                gap: 20px;
            }

            .content {
                font-size: 18px;
                font-weight: 700;
                color: $color-primary;
                position: relative;

                i {
                    font-size: 36px;
                    position: absolute;
                    right: -10px;
                    transform: none;
                    bottom: -6px;

                    &::before{
                        color: #000;
                    }
                }
            }
        }
    }

    .cards-posts .box-card{
        margin: 0 0 40px 0;
    }
}

.tag-category {
    border: 1px solid #359FB4;
    border-radius: 8px;
    width: max-content;
    padding: 4px 8px;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
}

.box-result{
    margin: 40px 0 0 0;

    &:first-child{
        margin: 20px 0 0 0;
    }

    a{
        padding: 45px;
        border-radius: 24px;
        border: 1px solid rgba(83, 179, 198, 0.40);
        background: $white;
        transition: 0.2s linear;
        text-decoration: none;
        display: grid;
        grid-template-columns: 98% 2%;

        @include max-lg{
            display: block;
            padding: 30px;
        }

        &:hover{
            border: 1px solid rgba(83, 179, 198, 1);
            transition: 0.2s linear;
        }

        .arrow {
            @include max-lg{
                display: flex;
                justify-content: end;
            }
        }
    }

    p{
        font-size: 15px;
        line-height: 23px;
        font-weight: 400;
    }

    h2{
        font-size: 20px;
        font-weight: 700;

        @include max-lg{
            margin: 0 0 20px 0;
            font-size: 18px;
        }
    }

    i{
        font-size: 30px;
        transform: rotate(-90deg);
        display: block;
        width: 30px;
        height: 30px;

        &:before{
            color: $color-primary3;
        }
    }
}

.result-blocks {
    .box-title-result {
        h2 {
            font-size: 28px;
            font-weight: 600;
        }
    }

    .result-block-item{
        margin: 45px 0;

        &:first-child{
            margin: 0 0 45px 0;
        }
    }
}