.alphabet-filter{
    .alphabet{
        list-style: none;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 0 60px 0;

        @include max-lg{
            margin: 0 0 30px 0;
            overflow: scroll;
            justify-content: start;
        }

        li{
            a{
                width: 44px;
                height: 44px;
                display: flex;
                color: $color-primary;
                font-weight: 700;
                line-height: 160%;
                text-decoration: none;
                justify-content: center;
                align-items: center;

                &:hover{
                    border: 1px solid $color-primary3;
                    border-radius: 100px;
                    background: $white;
                }
            }

            &.active{
                a{
                    border: 1px solid $color-primary3;
                    border-radius: 100px;
                    background: $white;
                }
            }
        }
    }

    #SearchForm-alphabet{
        margin-bottom: 60px;

        @include max-lg{
            margin-bottom: 30px;
        }

        .input-group {
            gap: 30px;

            @include max-lg{
                gap: 0;
            }
        
            input {
                padding: 20px 24px;
                border-radius: 100px;
                border: 1px solid #53B3C6;
                font-weight: 700;
                color: $color-primary2;
                background-image: url(../../assets/icons/search.svg);
                background-repeat: no-repeat;
                background-position: 20px 50%;
                padding-left: 70px;
                appearance: none;

                @include max-lg{
                    padding: 17px 15px 17px 50px;
                    background-position: 10px 50%;
                }

                &::placeholder{
                    opacity: 1;
                    color: $color-primary2;
                }

                &::-webkit-search-cancel-button {
                    display: none;
                }
            }

            button {
                background: #014150;
                border: none;
                padding: 20px 24px;
                border-radius: 100px !important;
                color: #fff;
                font-weight: 700;
                display: inline-flex;
                transition: 0.2s linear;

                @include max-lg{
                    width: 45px;
                    height: 45px;
                    padding: 0;
                    position: absolute;
                    right: 10px;
                    color: transparent;
                    top: 7px;
                    background-image: url('../../assets/icons/arrow-right-blue.svg');
                    background-size: 55px;
                    background-repeat: no-repeat;
                    background-position: 50%;
                    z-index: 6;
                    display: block;
                    font-size: 1px;
                }

                &:hover{
                    background: $color-primary3;
                    transition: 0.2s linear;

                    @include max-lg{
                        background: $color-primary;
                    }
                }

                i{
                    font-size: 40px;
                    height: 20px;
                    margin: -14px 0 0 0;
                    width: 28px;

                    @include max-lg{
                        display: none;
                    }
                }
            }

        }
    }

    .list-term{
        background: $white;
        border: 1px solid rgba(83, 179, 198, 0.40);
        border-radius: 24px;
        padding: 30px;
        margin-top: 40px;
        display: grid;
        grid-template-columns: 25% 75%;
        transition: 0.2s linear;

        @include max-lg{
            display: block;
        }

        &:hover{
            border: 1px solid rgba(83, 179, 198, 1);
            transition: 0.2s linear;
        }

        .term{
            h2{
                font-size: 18px;
                font-weight: 700;
                color: $color-primary;

                @include max-lg{
                    margin: 0 0 20px 0;
                }
            }
        }

        .description-term{
            display: flex;
            gap: 10px;

            @include max-lg{
                gap: 0;
            }

            i{
                font-size: 40px;
                display: flex;
                margin-top: -10px;
                color: $color-primary3;

                @include max-lg{
                    margin-left: -10px;
                }
            }
        }
    }
}