.share-partilha, .shared-post {
    position: relative;
    display: inline-block;

    .tooltip {
        display: none;
        position: absolute;
        top: -50px; /* Adjust the top position as needed */
        left: 0;
        background-color: transparent;
        padding: 0 0 30px 0;
        border: none;
        z-index: 1;
        opacity: 1;

        @include max-lg{
            left: inherit;
            right: 0;
        }

        /*&.news-details-share{
            top: 7px;
            padding: 30px 0 0 0;

            .social-share{
                background-color: $color-primary4;

                .box-social-share{
                    a{
                        display: block;
                        text-align: center;
                    }
                }
            }
        }*/

        &.news-details-share{
            .social-share{
                .box-social-share{
                    a{
                        display: block;
                        text-align: center;
                    }
                }
            }
        }

        .social-share{
            display: inline-flex;
            background-color: $white;
            padding: 4px;
            border-radius: 8px;
            position: relative;

            &::before{
                display: block;
                content: '';
                background: url(../../assets/icons/arrow-social-share.png);
                width: 22px;
                height: 10px;
                position: absolute;
                bottom: -9px;
                left: 14px;
            }

            .box-social-share{
                width: 32px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;

                span{
                    transition: 0.3s linear;

                    &:hover:before{
                        transition: 0.3s linear;
                        color: $color-primary;
                    }
                }
            }
        }
    }

    &:hover .tooltip, &:focus .tooltip, &:focus-within .tooltip, &:focus-visible .tooltip {
        display: block;
    }
}