.newsletter {
    background: $color-primary;
    padding: 75px 0;

    @include max-xl {
        padding: 75px 20px;
    }

    .col-lg-12 {
        padding: 0 200px;
        display: grid;
        grid-template-columns: 210px auto;

        @include max-xl {
            padding: 0;
            display: block;
        }

        .img-newsletter {
            @include max-xl {
                float: left;
                margin-right: 40px;
                margin-bottom: 40px;
            }
        }

        .title-newsletter {
            font-size: 2.75rem;

            @include max-md{
                font-size: 2.125rem;
                line-height: 100%;
            }
        }
    }
}

.logos-footer {
    padding: 70px 0;
    border-bottom: 1px solid rgba(1, 65, 80, 0.30);
    margin: 0 80px;

    @media only screen and (max-width: 1600px) {
        margin: 0 20px;
    }

    @include max-xl {
        display: none;
    }

    .box-logos {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 80px;

        @media only screen and (max-width: 1600px) {
            gap: 45px;
            margin: 0;
        }

        img {
            height: 74px;
            display: block;
            object-fit: contain;
            width: 100px;
        }
    }
}

footer {
    margin: 0 80px;
    padding: 70px 0;
    border-bottom: 1px solid rgba(1, 65, 80, 0.30);

    @media only screen and (max-width: 1600px) {
        margin: 0 20px;
    }

    @include max-xl {
        padding-bottom: 0;
        border-bottom: none;
    }

    .logo-footer {
        margin: 0 0 60px 0;

        @include max-xl {
            margin: 0 0 30px 0;
        }
    }

    ul.menu-footer {
        list-style: none;
        padding: 0;
        display: grid;
        grid-template-columns: repeat(3, 33%);
        gap: 10px;
        margin: 0;

        @include max-xl {
            display: block;
            margin: 0 0 0 -10px;
        }

        li {
            a {
                color: $color-primary;
                font-weight: 500;
                font-size: $font-size-base;
                text-decoration: none;
                display: inline-flex;
                align-items: center;
                transition: 0.3s linear;

                &:hover, &:focus {
                    text-decoration: underline;
                    color: $color-primary3;
                    transition: 0.3s linear;
                }

                i {
                    &::before {
                        font-size: 30px;
                        color: $color-primary3;
                        transform: rotate(-90deg) !important;
                        display: inline-flex;
                        width: 30px;
                        height: 30px;
                        margin-right: 5px;
                    }
                }
            }
        }
    }

    .col-2-footer {
        display: inline-flex;
        gap: 60px;
        justify-content: end;

        @media only screen and (max-width: 1600px) {
            gap: 40px;
        }

        @include max-xl {
            gap: 0;
            display: block;
            margin: 30px 0 0 0;
        }

        .redes-sociais {

            .title-menusfooter {
                font-size: 16px;
                font-weight: 700;
                margin: 0 0 22px 0;
            }

            .box {
                display: inline-flex;
                gap: 20px;

                @media only screen and (max-width: 1600px) {
                    gap: 15px;
                }

                a {
                    text-decoration: none;

                    &.icon-facebook {
                        background-image: url('../../assets/icons/facebook.svg');
                        width: 40px;
                        height: 40px;
                        background-repeat: no-repeat;
                        display: block;
                        transition: 0.3s linear;

                        &:hover, &:focus {
                            background-image: url('../../assets/icons/facebook-hover.svg');
                            transition: 0.3s linear;
                        }
                    }

                    &.icon-youtube {
                        background-image: url('../../assets/icons/youtube.svg');
                        width: 40px;
                        height: 40px;
                        background-repeat: no-repeat;
                        display: block;
                        transition: 0.3s linear;

                        &:hover, &:focus {
                            background-image: url('../../assets/icons/youtube-hover.svg');
                            transition: 0.3s linear;
                        }
                    }

                    &.icon-twitter {
                        background-image: url('../../assets/icons/twitter.svg');
                        width: 40px;
                        height: 40px;
                        background-repeat: no-repeat;
                        display: block;
                        transition: 0.3s linear;

                        &:hover, &:focus {
                            background-image: url('../../assets/icons/twitter-hover.svg');
                            transition: 0.3s linear;
                        }
                    }

                    &.icon-linkedin {
                        background-image: url('../../assets/icons/linkedin.svg');
                        width: 40px;
                        height: 40px;
                        background-repeat: no-repeat;
                        transition: 0.3s linear;
                        display: block;

                        &:hover, &:focus {
                            background-image: url('../../assets/icons/linkedin-hover.svg');
                            transition: 0.3s linear;
                        }
                    }

                    &.icon-instagram {
                        background-image: url('../../assets/icons/instagram.svg');
                        width: 40px;
                        height: 40px;
                        background-repeat: no-repeat;
                        display: block;
                        transition: 0.3s linear;

                        &:hover, &:focus {
                            background-image: url('../../assets/icons/instagram-hover.svg');
                            transition: 0.3s linear;
                        }
                    }

                    img {
                        width: 40px;
                        height: 40px;
                        opacity: 0;
                    }
                }
            }
        }

        .buttons-intranet {

            @include max-xl {
                margin: 50px 0 0 0;
            }

            a {
                display: inline-flex;
                padding: 14px 20px;
                border-radius: 4px;
                border: 1px solid #014150;
                align-items: center;
                gap: 30px;
                font-weight: 700;
                text-decoration: none;
                transition: 0.3s linear;

                @include max-xl {
                    width: 100%;
                }

                &:hover, &:focus {
                    background-color: #D4F0F6;
                    transition: 0.3s linear;
                }

                &:first-child {
                    margin-right: 30px;
                }

                &:last-child {
                    @include max-xl {
                        width: fit-content;
                    }
                }

                i {
                    width: 49px;
                    height: 49px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    display: inline-flex;

                    &.ico-intranet {
                        background-image: url('../../assets/icons/icon-intranet.svg');
                    }

                    &.ico-area-reservada {
                        background-image: url('../../assets/icons/icon-area-reservada.svg');
                    }
                }
            }
        }
    }
}

.copyright {
    padding: 70px 0;
    margin: 0 80px;

    @media only screen and (max-width: 1600px) {
        margin: 0 20px;
    }

    @include max-xl {
        padding: 70px 20px;
        text-align: center;
    }

    .row {
        @include max-xl {
            flex-direction: column-reverse;
        }
    }

    .logos-copy {
        display: inline-flex;
        justify-content: center;
        gap: 60px;

        img{
            object-fit: contain;
        }

        @include max-xl {
            gap: 20px;
            margin: 0 0 30px 0;
        }

        @include max-sm {
            display: grid;
            place-items: center;
        }
    }

    .develop-loba {
        text-align: right;
    }
}

.fixed-logos {
    position: fixed;
    bottom: 20px;
    right: 90px;
    display: inline-flex;
    z-index: 9;

    @media only screen and (max-width: 1600px) {
        right: 30px;
    }

    @include max-xl {
        display: none;
    }

    a {
        width: 46px;
        height: 46px;

        &:first-child {
            margin-right: 0;

            i.icon-up {
                width: 46px;
                height: 46px;
                display: inline-flex;
                background: $color-primary3;
                border-radius: 4px;
                justify-content: center;
                align-items: center;
                font-size: 40px;
                transition: 0.3s linear;

                &:hover, &:focus {
                    background: $color-primary;
                    transition: 0.3s linear;

                    &:before {
                        color: $white;
                    }
                }
            }
        }
    }
}

.back-top {
    transition: opacity 0.3s ease;

    &:hover {
        opacity: 0.8;
    }
}