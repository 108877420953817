.title-plus-textarea{
    padding-top: 60px;
    padding-bottom: 60px;

    @include max-lg{
        padding-top: 30px;
        padding-bottom: 30px;
    }

    h2{
        font-size: 2.4375rem;
        opacity: 0.87;
    }
}

.slider-widthcontainer{
    .slider-fluid{
        .swiperSliderFluid{
            .pagination{
                .swiper-button-next{
                    right: 0;
                }

                .swiper-button-prev{
                    right: 75px;
                }

                .line{
                    right: 160px;
                }
            }
        }
    }
}

.slider-fluid{
    padding-top: 60px;
    padding-bottom: 60px;

    @include max-lg{
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .swiperSliderFluid{
        padding-bottom: 100px;

        .swiper-slide{
            img{
                width: 100%;
            }
        }

        .pagination {
            .line {
                width: 120px;
                height: 1px;
                background: $color-primary3;
                position: absolute;
                right: 350px;
                bottom: 30px;
            }

            .swiper-button-prev {
                left: inherit;
                right: 275px;
                bottom: 0px;
                top: inherit;
                width: 60px;
                height: 60px;
                background: $color-primary;
                border-radius: 100px;
                transition: 0.3s linear;

                &.swiper-button-disabled {
                    width: 40px;
                    height: 40px;
                    bottom: 10px;
                    right: 280px;
                    transition: 0.3s linear;
                }

                i {
                    font-size: 40px;
                    transform: rotate(-180deg);

                    &:before {
                        color: $white;
                        display: inherit;
                    }
                }

                &:after {
                    display: none;
                }
            }

            .swiper-button-next {
                right: 200px;
                bottom: 0px;
                top: inherit;
                width: 60px;
                height: 60px;
                background: $color-primary;
                border-radius: 100px;
                transition: 0.3s linear;

                &.swiper-button-disabled {
                    width: 40px;
                    height: 40px;
                    bottom: 10px;
                    right: 215px;
                    transition: 0.3s linear;
                }

                i {
                    font-size: 40px;

                    &:before {
                        color: $white;
                        display: inherit;
                    }
                }

                &:after {
                    display: none;
                }
            }
        }
    }
}


.text-featured{
    padding-top: 60px;
    padding-bottom: 60px;

    @include max-lg{
        padding-top: 30px;
        padding-bottom: 30px;
    }

    h6{
        font-size: 25px;
        line-height: 160%;
        font-weight: 500;
        color: $color-primary;

        @include max-lg{
            font-size: 20px;
            font-weight: 400;
        }
    }
}

.text-simple-small{
    padding-top: 60px;
    padding-bottom: 60px;

    @include max-lg{
        padding-top: 30px;
        padding-bottom: 30px;
    }

    p{
        font-size: 16px; 
        line-height: 160%;
        font-weight: 400;
        color: $color-primary;
    }
}

.texts-medias{
    padding-top: 60px;
    padding-bottom: 60px;

    @include max-lg{
        padding-top: 30px;
        padding-bottom: 30px;
    }

    &.block-textimage{
        p{
            text-overflow: none;
            overflow: initial;
            height: auto;
        }
    }

    h2{
        font-size: 34px;
        font-weight: 700;
        color: $color-primary;
        opacity: 0.87;
        margin: 0 0 30px 0;
    }

    p{
        font-size: 16px;
        font-weight: 400;
        line-height: 160%;
        height: 130px;
        align-self: stretch;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    
    .button-text{
        margin: 30px 0 0 0;

        a, button{
            padding: 13px 20px;
            border: 1px solid rgba(1, 65, 80, 0.40);
            border-radius: 50px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            font-weight: 700;
            color: $color-primary;
            font-size: 14px;
            transition: 0.2s linear;
            background: transparent;

            &:hover, &:focus{
                background: #D4F0F6;
                transition: 0.2s linear;
            }

            i{
                font-size: 40px;
                color: $color-primary3;
                display: flex;
                width: 30px;
                height: 15px;
                margin-top: -24px;
                margin-left: 10px;
            }
        }
    }

    .image{
        img{
            width: 100%;
            border-radius: 24px;
        }
    }
    
    .pl-50{
        padding-left: 50px;

        @include max-lg{
            padding-left: 10px;
            margin-top: 30px;
        }
    }

    .pr-50{
        padding-right: 50px;

        @include max-lg{
            padding-right: 10px;
            margin-bottom: 30px;
        }
    }
}

.points-stars{
    padding-top: 60px;
    padding-bottom: 60px;

    @include max-lg{
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .starsblocks{
        display: grid;
        grid-template-columns: auto auto auto auto;
        gap: 40px;
    }

    h2{
        margin: 0 0 40px 0;
        font-size: 2.4375rem;
        opacity: 0.87;
    }

    .box-star{
        border-radius: 24px;
        border: 1px solid #53B3C6;
        padding: 30px;
        text-align: center;
        background: $white;

        @include max-lg{
            margin-bottom: 20px;
        }

        h3{
            margin: 30px 0 16px 0;
            font-size: 1.5625rem;
            line-height: 160%;
        }

        p{
            font-size: 14px;
            font-weight: 400;
            line-height: 160%;
            margin: 0;
        }
    }
}

.testimonials{
    padding-top: 60px;
    padding-bottom: 60px;

    @include max-lg{
        padding-top: 30px;
        padding-bottom: 30px;
    }

    h2{
        margin: 0 0 40px 0;
        font-size: 2.4375rem;
        opacity: 0.87;
    }

    .swiper{
        overflow: initial;

        @include max-lg{
            overflow: hidden;
        }

        .swiper-slide{
            padding: 0 0 120px 0;

            .box-testimonial{
                border-radius: 24px;
                border: 1px solid #53B3C6;
                padding: 30px;
                display: flex;
                gap: 30px;
                background: $white;

                img{
                    width: 70px;
                    height: 70px;
                    object-fit: cover;
                    border-radius: 100px;
                }
        
                h3{
                    margin: 0 0 15px 0;
                    font-weight: 700;
                    font-size: 1.5625rem;
                    line-height: 160%;
                }
        
                p{
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 160%;
                    margin: 0;
                }
            }
        }

        .pagination {
            .line {
                width: 120px;
                height: 1px;
                background: $color-primary3;
                position: absolute;
                right: 170px;
                bottom: 30px;
            }

            .swiper-button-prev {
                left: inherit;
                right: 80px;
                bottom: 0px;
                top: inherit;
                width: 60px;
                height: 60px;
                background: $color-primary;
                border-radius: 100px;
                transition: 0.3s linear;

                &.swiper-button-disabled {
                    width: 40px;
                    height: 40px;
                    bottom: 10px;
                    right: 80px;
                    transition: 0.3s linear;
                }

                i {
                    font-size: 40px;
                    transform: rotate(-180deg);

                    &:before {
                        color: $white;
                        display: inherit;
                    }
                }

                &:after {
                    display: none;
                }
            }

            .swiper-button-next {
                right: 0px;
                bottom: 0px;
                top: inherit;
                width: 60px;
                height: 60px;
                background: $color-primary;
                border-radius: 100px;
                transition: 0.3s linear;

                &.swiper-button-disabled {
                    width: 40px;
                    height: 40px;
                    bottom: 10px;
                    right: 20px;
                    transition: 0.3s linear;
                }

                i {
                    font-size: 40px;

                    &:before {
                        color: $white;
                        display: inherit;
                    }
                }

                &:after {
                    display: none;
                }
            }
        }
    }
}

.single-image{
    img{
        width: 100%;
    }
}

.gallery-images{
    padding-top: 60px;
    padding-bottom: 60px;

    @include max-lg{
        padding-top: 30px;
        padding-bottom: 30px;
    }

    h2{
        margin: 0 0 40px 0;
        font-size: 2.4375rem;
        opacity: 0.87;
    }

    img{
        border-radius: 24px;
        width: 100%;
    }

    .legend{
        padding: 30px 20px 40px 20px;
        margin: 0 0 40px 0;
    }
}

.block-documents{
    padding-top: 60px;
    padding-bottom: 60px;

    @include max-lg{
        padding-top: 30px;
        padding-bottom: 30px;
    }

    h2{
        margin: 0 0 40px 0;
        font-size: 2.4375rem;
        opacity: 0.87;
    }

    .cards-documentos{
        margin: 0;

        .box-card {
            margin: 0 0 40px 0;
        }
    }
}

.gallery-videos{
    padding-top: 60px;
    padding-bottom: 60px;

    @include max-lg{
        padding-top: 30px;
        padding-bottom: 30px;
    }

    h2{
        margin: 0 0 40px 0;
        font-size: 2.4375rem;
        opacity: 0.87;
    }

    img{
        border-radius: 24px;
        width: 100%;
    }

    .legend{
        padding: 30px 20px 20px 20px;
        margin: 0 0 40px 0;

        .metas{
            display: flex;
            justify-content: space-between;
            
            .author {
                margin: 10px 0 0 0;
                padding: 0 0 10px 0;
                width: 100%;
                font-size: 14px;
                line-height: 160%;
                display: inline-flex;
                align-items: center;
    
                span{
                    padding-left: 5px;
                }
    
                i{
                    font-size: 36px;
                    width: 24px;
                    height: 24px;
                    display: inline-block;
                    position: relative;
                    color: $color-primary2;
    
                    &:before{
                        position: absolute;
                        left: -7px;
                        top: -8px;
                    }
                }
            }
        }
    }
}

.related-links{
    padding-top: 60px;
    padding-bottom: 60px;

    @include max-lg{
        padding-top: 30px;
        padding-bottom: 30px;
    }

    h2{
        margin: 0 0 40px 0;
        font-size: 2.4375rem;
        opacity: 0.87;
    }

    .box-related{
        border-radius: 6px;
        border: 1px solid #BAE1E8;
        background: #D4F0F6;
        display: flex;
        padding: 15px 20px;
        gap: 10px;
        text-decoration: none;
        transition: 0.2s linear;

        @include max-lg{
            margin-bottom: 15px;
        }

        &:hover, &:focus{
            transition: 0.2s linear;
            background: #BAE1E8;
        }

        i{
            font-size: 30px;
            width: 30px;
            height: 30px;
            margin-top: -9px;
        }
    }
}

.block-faqs{
    padding-top: 60px;
    padding-bottom: 60px;

    @include max-lg{
        padding-top: 30px;
        padding-bottom: 30px;
    }

    h2{
        margin: 0 0 40px 0;
        font-size: 2.4375rem;
        opacity: 0.87;
    }

    .accordion-item{
        border-radius: 24px;
        border: 1px solid rgba(83, 179, 198, 0.40);
        background: #FFF;
        padding: 30px;
        margin-bottom: 20px;

        .accordion-header{
            display: flex;
        }
    
        .accordion-button{
            border-radius: 24px;
            padding: 0;
            font-size: 18px;
            color: $color-primary;
            font-weight: 700;
            display: grid;
            grid-template-columns: 95% 5% 0;

            &:focus{
                border: 1px solid;
                border-radius: 0;
            }

            &:not(.collapsed) {
                box-shadow: none;
                background-color: transparent;

                i.icon-plus{
                    display: none;
                }
                
                i.icon-minus{
                    display: block;
                    color: $color-primary2;
                    font-size: 40px;
                    width: 40px;
                    height: 40px;
                }

                &::after {
                    background-image: none;
                }
            }

            i.icon-minus{
                display: none;
            }

            i.icon-plus{
                display: block;
                color: $color-primary2;
                font-size: 40px;
                width: 40px;
                height: 40px;
            }

            &::after {
                background-image: none;
            }
        }

        .accordion-body {
            padding: 20px 0 0 0;
        }
    }
}