.custom-file {
	position: relative;
	
	input {
		position: absolute !important;
		width: 1px !important;
		height: 1px !important;
		padding: 0 !important;
		margin: -1px !important;
		overflow: hidden !important;
		clip: rect(0, 0, 0, 0) !important;
		white-space: nowrap !important;
		border: 0 !important;
	}
}

.custom-file__mask {
	display: block;
	height: $inputFileHeight;
	padding: 0 calc($grid-gutter-width / 2);
	border-radius: $inputFileBorderRadius;
	border: $inputFileBorder;
	background-color: $inputFileBackground;
	color: $inputFileColor;
	cursor: pointer;
	font-size: $inputFileFontSize;
	font-weight: $inputFileFontWeight;
	line-height: $inputFileHeight;
	transition: $inputFileTransition;

	&:hover,
	&:focus,
	&:active {
		background-color: $inputFileBackgroundHover;
	}
}