.validate-form {
	position: relative;
}

.form-group {
	margin-bottom: $formGroupMarginBottom;
}

label {
	max-width: 100%;
	@if $formGroupLabelMarginBottom != 0 {
		margin-bottom: $formGroupLabelMarginBottom;
	}
	@if $formGroupLabelWeight != 0 {
		font-weight: $formGroupLabelWeight;
	}
}

label.error{
	display: block;
    width: 100%;
    margin-top: $form-feedback-margin-top;
    @include font-size($form-feedback-font-size);
    font-style: $form-feedback-font-style;
    color: var(--#{$prefix}form-invalid-color);
}

.form-check{
	label.error{
		margin-top: 0;
		line-height: 1;
	}
}


.open-rgpd {
	
	&.loading {
		position: relative;
		color: transparent !important;
		
		&:after {
			content: '';
			display: block;
			width: 20px;
			height: 20px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			border-radius: 50%;
			border: 2px solid transparent;
			border-top-color: white;
			border-right-color: white;
			animation: linear centeredRotation 0.8s infinite;
		}
	}
}

// RGPD Box

.rgpd {
	display: none;
	max-width: 300px;
	padding: 30px;
	position: absolute;
	bottom: 0;
	left: 0;
	box-shadow: 0 5px 20px rgba($color-default, 0.7);
	border-radius: 15px;
	background-color: white;
	color: $color-default;
	font-size: 12px;
	line-height: 20px;

	&.rgpd--open {
		display: block;
	}

	&:after {
		content: '';
		display: block;
		width: 100%;
		clear: both;
	}

	button {
		min-width: 0;
		width: calc(50% - $grid-gutter-width);
		float: left;

		&:last-child {
			float: right;
		}
	}
}

.rgpd__title {
	margin-top: 0;
	color: $color-primary;
	font-size: 16px;
	font-weight: 900;
	line-height: 20px;
}

.form-newsletter{
	display: grid;
	grid-template-columns: 70% 30%;
	margin-top: 45px;

    @include max-lg{
		display: block;
    	margin-top: 45px;
	}

	.btn{
		&.btn-form-1{
			color: $color-primary;

			&:hover{
				color: $white;
			}
		}
	}

	label.error {
		color: $white;
	}

	label#check\[0\]-error {
		text-decoration: none;
		font-size: 13px;
	}

	.rgpd{
		label{
			a{
				text-decoration: underline;
			}
		}
	}

	label.form-label{
		position: absolute;
		z-index: -1;
		color: $white;
	}
}