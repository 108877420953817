.content-pages-tabs {
    padding-bottom: 120px;

    @include max-lg{
        padding-bottom: 60px;
    }

    .colum-2{
        padding-left: 80px;

        h2{
            font-size: 2.438rem;

            @include max-md{
                font-size: 1.5625rem;
                line-height: 160%;
            }
        }

        @include max-lg{
            padding-left: 10px;
            margin-top: 30px;
        }

        .description-col{
            margin: 30px 0 20px 0;
        }

        #SearchForm-Tabs{

            .input-group {
                gap: 30px;

                @include max-lg{
                    gap: 0;
                }
            
                input {
                    padding: 20px 24px;
                    border-radius: 100px;
                    border: 1px solid #53B3C6;
                    font-weight: 700;
                    color: $color-primary2;
                    background-image: url(../../assets/icons/search.svg);
                    background-repeat: no-repeat;
                    background-position: 20px 50%;
                    padding-left: 70px;
                    appearance: none;

                    @include max-lg{
                        padding: 17px 15px 17px 50px;
                        background-position: 10px 50%;
                    }

                    &::placeholder{
                        opacity: 1;
                        color: $color-primary2;
                    }

                    &::-webkit-search-cancel-button {
                        display: none;
                    }
                }

                button {
                    background: $color-primary;
                    border: none;
                    padding: 20px 24px;
                    border-radius: 100px !important;
                    color: #fff;
                    font-weight: 700;
                    display: inline-flex;
                    transition: 0.2s linear;

                    @include max-lg{
                        width: 45px;
                        height: 45px;
                        padding: 0;
                        position: absolute;
                        right: 10px;
                        color: transparent;
                        top: 7px;
                        background-image: url('../../assets/icons/arrow-right-blue.svg');
                        background-size: 55px;
                        background-repeat: no-repeat;
                        background-position: 50%;
                        z-index: 6;
                        display: block;
                        font-size: 1px;
                    }

                    &:hover{
                        background: $color-primary3;
                        transition: 0.2s linear;

                        @include max-lg{
                            background: $color-primary;
                        }
                    }

                    i{
                        font-size: 40px;
                        height: 20px;
                        margin: -14px 0 0 0;
                        width: 28px;

                        @include max-lg{
                            display: none;
                        }
                    }
                }

            }
        }
    }

    .menu-tabs{
        list-style: none;
        padding: 0;

        li{
            border-radius: 6px;
            border: 1px solid #BAE1E8;
            background: #D4F0F6;
            padding: 10px 20px;
            margin-bottom: 12px;
            transition: 0.2s linear;

            a{
                font-size: 14px;
                font-weight: 700;
                color: $color-primary;
                text-decoration: none;
                display: inline-flex;
                width: 100%;
                justify-content: space-between;

                i.icon-arrow-down {
                    transform: rotate(-90deg);
                    font-size: 23px;
                    width: 23px;
                    height: 20px;
                    color: $color-primary2;
                }
            }

            &:hover{
                background: $white;
                transition: 0.2s linear;
            }

            &.active{
                background: $white;
            }
        }
    }

    .grid-result-tabs{
        margin-top: 90px;

        .box-result{
            margin: 0 0 40px 0;
            padding: 0 20px;      
            
            .logo{
                img{
                    width: 100px;
                    height: 100px;
                    object-fit: cover;
                    border-radius: 6px;
                }
            }
    
            a{
                display: flex;
                gap: 30px;
                height: 100%;
                width: 100%; 
            }

            .logo {
                display: flex;
                align-items: center;
            }

            .content {
                font-size: 18px;
                font-weight: 700;
                color: $color-primary;
                position: relative;
                height: 100%;
                display: flex;
                align-items: center;
                width: 100%;

                i {
                    font-size: 36px;
                    position: absolute;
                    right: -10px;
                    transform: none;
                    bottom: -6px;

                    &::before{
                        color: #000;
                    }
                }
            }
        }
    }
    
}

.content-tabs-interna{
    .main-block{
        margin: 40px 0 0 0;
        background: $white;
        padding: 30px;
        border-radius: 24px;
        border: 1px solid rgba(83, 179, 198, 0.40);
        display: flex;
        gap: 40px;
        transition: 0.2s linear;

        @include max-lg{
            display: block;
        }

        &:hover{
            border: 1px solid $color-primary3;
            transition: 0.2s linear;
        }

        .image{
            img{
                width: 160px;
                height: 160px;
                object-fit: cover;
                border-radius: 6px;

                @include max-lg{
                    margin: 0 0 20px 0;
                }
            }
        }

        h2{
            font-size: 25px;
            font-weight: 700;
            line-height: 160%;
            color: $color-primary;
        }

        p{
            margin: 20px 90px 15px 0;

            @include max-lg{
                margin: 20px 0 15px 0;
            }
        }

        .links{
            margin: 0 0 15px 0;
            display: inline-flex;
            width: 100%;       
            
            &:last-child{
                margin: 0;
            }

            .pdf-download{
                margin-right: 30px;
            }

            i{
                width: 20px;
                height: 20px;
                background-size: contain;
                background-position: 50%;
                background-repeat: no-repeat;
                display: inline-flex;
                margin-right: 8px;

                &.icon-world{
                    background-image: url('../../assets/icons/icon-world.svg');
                }

                &.icon-pdf{
                    background-image: url('../../assets/icons/icon-pdf.svg');
                }
            }

            a{
                font-size: 14px;
                font-weight: 500;
                color: $color-primary;

                &:hover{
                    color: $color-primary3;
                }
            }
        }
    }

    .secondary-blocks{
        padding: 60px 90px 10px 90px;
        display: flex;
        gap: 40px;

        @include max-lg{
            padding: 30px 0 10px 0;
        }

        .image{
            img{
                width: 100px;
                height: 100px;
                object-fit: cover;
                border-radius: 6px;
            }
        }

        h2{
            font-size: 20px;
            font-weight: 700;
            line-height: 160%;
            color: $color-primary;
        }

        p{
            margin: 20px 0 15px 0;
        }

        .links{
            margin: 0 0 15px 0;
            display: inline-flex;
            width: 100%;
            
            @include max-lg{
                display: block;
            }
            
            &:last-child{
                margin: 0;
            }

            .pdf-download{
                margin-right: 30px;
                display: inline-flex;

                @include max-lg{
                    display: block;
                    margin-right: 0;
                }
            }

            i{
                width: 20px;
                height: 20px;
                background-size: contain;
                background-position: 50%;
                background-repeat: no-repeat;
                display: inline-flex;
                margin-right: 8px;

                &.icon-world{
                    background-image: url('../../assets/icons/icon-world.svg');
                }

                &.icon-pdf{
                    background-image: url('../../assets/icons/icon-pdf.svg');
                }
            }

            a{
                font-size: 14px;
                font-weight: 500;
                color: $color-primary;

                &:hover{
                    color: $color-primary3;
                }
            }
        }
    }
}