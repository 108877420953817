.cards-documentos{
    margin: 50px 0 0 0;

    @include max-lg{
        margin: 20px 0 0 0;
    }

    .box-card{
        border-radius: 24px;
        border: 1px solid rgba(83, 179, 198, 0.40);
        background: #FFF;
        padding: 20px;
        display: block;
        margin: 40px 0 0 0;
        transition: 0.2s linear;
        font-size: 14px;

        &:hover{
            background-color: #D4F0F6;
            transition: 0.2s linear;
        }

        &:focus{
            background-color: #D4F0F6;
            transition: 0.2s linear;
        }

        a{
            text-decoration: none;
        }

        h3{
            font-size: 18px;
            margin: 20px 0 10px 0;
            font-weight: 700;

            a{
                text-decoration: none;
            }
        }

        h2{
            font-size: 18px;
            margin: 20px 0 10px 0;
            font-weight: 700;

            a{
                text-decoration: none;
            }
        }

        .author {
            margin: 30px 0 0 0;
            padding: 0 0 10px 0;
            border-bottom: 1px solid #AEDEE8;
            width: 100%;
            font-size: 14px;
            line-height: 160%;
            display: inline-flex;

            span{
                padding-left: 5px;
            }

            i{
                font-size: 36px;
                width: 24px;
                height: 24px;
                display: inline-block;
                position: relative;

                &:before{
                    position: absolute;
                    left: -7px;
                    top: -8px;
                }
            }
        }

        .share {
            display: flex;
            justify-content: space-between;
            padding: 30px 0 0 0;

            a{
                position: relative;
                display: inline-flex;

                i{
                    width: 30px;
                    height: 20px;
                    font-size: 36px;

                    &:before{
                        position: absolute;
                        top: -7px;
                        color: $color-primary2;
                    }
                }
            }
        }
    }
}

.container.container-posts{
    padding: 0;

    .row{
        .col-lg-3.col-md-4.col-12{
            padding: 0 20px;
        }
    }
}

.cards-posts{
    margin: 50px 0 0 0;

    @include max-lg{
        margin: 20px 0 0 0;
    }

    .box-card{
        border: 1px solid rgba(83, 179, 198, 0.40);
        background: #FFF;
        display: block;
        margin: 40px 0 0 0;
        transition: 0.2s linear;
        font-size: 14px;
        border-radius: 24px;
        text-decoration: none;
        position: relative;

        .grid-share{
            display: flex;
            justify-content: space-between;
            align-items: center;

            .author {
                width: auto;
                display: grid;
                grid-template-columns: 24px auto;
            }

            .share {
                display: flex;
                justify-content: space-between;
                padding: 0 20px 20px 20px;
                position: relative;
                bottom: auto;
                right: auto;

                .share-partilha, .shared-post {
                    margin: 10px 0 0 0;

                    i.icon-share {
                        font-size: 30px;
                    }
    
                    a {
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }

        .image{
            position: relative;

            img{
                border-radius: 24px 24px 0 0;
                width: 100%;
                height: 190px;
                object-fit: cover;
            }

            .tags {
                position: absolute;
                top: 20px;
                left: 20px;

                span{
                    padding: 3px 12px;
                    color: #000;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 160%;
                    background-color: $white;
                    border-radius: 100px;
                }
            }
        }

        &:hover{
            background-color: #D4F0F6;
            transition: 0.2s linear;

            .bt-post {
                background: $color-primary2;
                transition: 0.2s linear;
                
                i{
                    color: $white;
                    transition: 0.2s linear;
                }
            }
        }

        &:focus{
            background-color: #D4F0F6;
            transition: 0.2s linear;
        }

        .bt-post {
            position: absolute;
            right: 20px;
            width: 40px;
            height: 40px;
            border-radius: 100px;
            top: 170px;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.2s linear;

            i{
                transform: rotate(-90deg);
                font-size: 25px;
                margin-left: 2px;
                color: $color-primary5;
                transition: 0.2s linear;
            }
        }

        .date-post{
            font-size: 13px;
            font-weight: 500;
            padding: 20px 20px 0 20px;
            display: inline-flex;
            align-items: center;
            gap: 5px;

            i{
                font-size: 20px;
            }
        }

        a{
            text-decoration: none;
        }

        h3{
            font-size: 18px;
            margin: 10px 0 10px 0;
            font-weight: 700;
            padding: 0 20px;

            a{
                text-decoration: none;
            }
        }

        h2{
            font-size: 18px;
            margin: 10px 0 10px 0;
            font-weight: 700;
            padding: 0 20px;
            min-height: 45px;

            a{
                text-decoration: none;
            }
        }

        p{
            padding: 0 20px;
            min-height: 100px;
        }

        .author {
            margin: 10px 0 0 0;
            padding: 0 20px 20px 20px;
            width: 100%;
            font-size: 14px;
            line-height: 160%;
            display: inline-flex;

            span{
                padding-left: 5px;
            }

            i{
                font-size: 36px;
                width: 24px;
                height: 24px;
                display: inline-block;
                position: relative;

                &:before{
                    position: absolute;
                    left: -7px;
                    top: -8px;
                }
            }
        }

        .share {
            display: flex;
            justify-content: space-between;
            padding: 30px 0 0 0;

            button{
                background: transparent;
                border: none;
                position: absolute;
                right: 20px;
                bottom: 20px;

                i{
                    width: 30px;
                    height: 20px;
                    font-size: 36px;

                    &:before{
                        position: absolute;
                        top: -7px;
                    }
                }
            }
        } 
    }

    .share {
        position: absolute;
        bottom: 20px;
        right: 20px;

        a{
            text-decoration: none;

            &.tit-partilha{
                background: transparent;
                border: none;
                display: flex;
                text-decoration: none;
                align-items: center;
    
                i{
                    width: 30px;
                    height: 20px;
                    font-size: 36px;
                    display: block;
    
                    &:before{
                        position: absolute;
                        top: -5px;
                    }
                }
            }
        }
    }
}

.cards-recruit{
    margin: 50px 0 0 0;

    @include max-lg{
        margin: 20px 0 0 0;
    }

    .box-card{
        border: 1px solid rgba(83, 179, 198, 0.40);
        background: #FFF;
        display: block;
        margin: 40px 0 0 0;
        transition: 0.2s linear;
        font-size: 14px;
        border-radius: 24px;
        text-decoration: none;
        position: relative;

        .image{
            position: relative;

            img{
                border-radius: 24px 24px 0 0;
                width: 100%;
                height: 190px;
                object-fit: cover;
            }

            .tags {
                position: absolute;
                top: 20px;
                left: 20px;

                span{
                    padding: 3px 12px;
                    color: #000;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 160%;
                    background-color: $white;
                    border-radius: 100px;
                }
            }
        }

        &:hover{
            background-color: #D4F0F6;
            transition: 0.2s linear;

            .bt-post {
                background: $color-primary2;
                transition: 0.2s linear;
                
                i{
                    color: $white;
                    transition: 0.2s linear;
                }
            }
        }

        &:focus{
            background-color: #D4F0F6;
            transition: 0.2s linear;
        }

        .bt-post {
            position: absolute;
            right: 20px;
            width: 40px;
            height: 40px;
            border-radius: 100px;
            top: 170px;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.2s linear;

            i{
                transform: rotate(-90deg);
                font-size: 25px;
                margin-left: 2px;
                color: $color-primary5;
                transition: 0.2s linear;
            }
        }

        .date-post{
            font-size: 13px;
            font-weight: 500;
            padding: 20px 20px 0 20px;
            display: inline-flex;
            align-items: center;
            gap: 5px;

            i{
                font-size: 20px;
            }
        }

        a{
            text-decoration: none;
        }

        h3{
            font-size: 18px;
            margin: 25px 0 10px 0;
            font-weight: 700;
            padding: 0 20px;

            a{
                text-decoration: none;
            }
        }

        h2{
            font-size: 18px;
            margin: 25px 0 10px 0;
            font-weight: 700;
            padding: 0 20px;

            a{
                text-decoration: none;
            }
        }

        p{
            padding: 0 20px;
        }

        .author {
            margin: 10px 0 0 0;
            padding: 0 20px 20px 20px;
            width: 100%;
            font-size: 13px;
            line-height: 160%;
            display: inline-flex;
            font-weight: 500;

            span{
                padding-left: 5px;
            }

            i{
                font-size: 26px;
                width: 18px;
                height: 19px;
                display: inline-block;
                position: relative;

                &:before{
                    position: absolute;
                    left: -7px;
                    top: -4px;
                }
            }
        }

        .share {
            display: flex;
            justify-content: space-between;
            padding: 30px 0 0 0;

            button{
                background: transparent;
                border: none;
                position: absolute;
                right: 20px;
                bottom: 20px;

                i{
                    width: 30px;
                    height: 20px;
                    font-size: 36px;

                    &:before{
                        position: absolute;
                        top: -7px;
                    }
                }
            }
        }

        .button-details{
            padding: 0 20px;

            button{
                border-radius: 50px;
                border: 1px solid rgba(1, 65, 80, 0.40);
                background: $white;
                font-weight: 700;
                width: 100%;
                text-align: left;
                padding: 10px 20px;
                font-size: 14px;
                display: flex;
                justify-content: space-between;
                margin: 20px 0 30px 0;
                color: $color-primary;

                i{
                    font-size: 30px;
                    transform: rotate(-90deg);
                    width: 30px;
                    height: 21px;
                }
            }
        }
    }
}