.wpcf7{
    .line{
        margin-bottom: 30px;

        @include max-md{
            margin-bottom: 0;
        }

        p{
            margin-bottom: 0 !important;
        }

        input, select, textarea{
            width: 100%;

            @include max-md{
                margin-bottom: 30px;
            }
        }

        input{
            padding: 10px 8px 10px 20px;
            border-radius: 24px;
            border: 1px solid $color-primary3;
            background: rgba(255, 255, 255, 0.50);
            font-size: 14px;
            font-weight: 700;

            &.wpcf7-not-valid{
                background: rgb(255 190 190 / 50%);
            }

            &:focus{
                background: rgba(255, 255, 255, 1);
            }

            &:focus-visible{
                border: 2px solid $color-primary2;
                background: rgba(255, 255, 255, 1);
            }

            &[type="file"]{
                border: none;
                background: transparent;
                font-weight: 500;
                padding-left: 0;
                border-radius: 0;
            }
        }

        select{
            padding: 10px 8px 10px 20px;
            border-radius: 24px;
            border: 1px solid $color-primary3;
            background: rgba(255, 255, 255, 0.50);
            font-size: 14px;
            font-weight: 700;

            &:focus{
                background: rgba(255, 255, 255, 1);
            }

            &:focus-visible{
                border: 2px solid $color-primary2;
                background: rgba(255, 255, 255, 1);
                outline: none;
            }
        }

        .wpcf7-checkbox, .wpcf7-acceptance{
            label{
                position: relative;
                margin-left: 0;

                span{
                    display: block;
                    margin-left: 7px;
                }

                input[type="checkbox"]{
                    opacity: 1;
                    width: 18px;
                    height: 18px;

                    &:checked~span::after{
                        content: '';
                        width: 20px;
                        height: 20px;
                        background: url('../../assets/img/check-active.svg');
                        display: block;
                        position: absolute;
                        left: -1px;
                        top: -1px;
                        z-index: 1;
                    }
                }

                /*&::after{
                    content: '';
                    width: 20px;
                    height: 20px;
                    background: url('../../assets/img/check.svg');
                    display: block;
                    position: absolute;
                    left: 0;
                }*/
            }
        }
        
        textarea{
            padding: 10px 8px 10px 20px;
            border-radius: 24px;
            border: 1px solid $color-primary3;
            background: rgba(255, 255, 255, 0.50);
            font-size: 14px;
            font-weight: 700;

            &:focus{
                background: rgba(255, 255, 255, 1);
            }

            &:focus-visible{
                border: 2px solid $color-primary2;
                background: rgba(255, 255, 255, 1);
                outline: none;
            }
        }

        label {
            color: #014150;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 160%;
            margin-left: 20px;
            margin-bottom: 6px;
        }

        &.col-6 {
            display: grid;
            width: 100%;
            grid-template-columns: 50% 50%;

            @include max-md{
                display: block;
            }
        }

        &.col-12 {
            display: block;
            width: 100%;

            @include max-md{
                display: block;
            }
        }

        &.col-8 {
            display: grid;
            width: 100%;
            grid-template-columns: 65% 35%;

            @include max-md{
                display: block;
            }
        }

        &.col-4 {
            display: grid;
            width: 100%;
            grid-template-columns: 33% 33% 33%;

            @include max-md{
                display: block;
            }
        }

        div {
            padding: 0 10px;
        }

        span{
            .wpcf7-checkbox{
                display: grid;
                grid-template-columns: repeat(3, 33%);

                @include max-md{
                    display: block;
                }

                label{
                    display: grid;
                    grid-template-columns: 25px auto;
                    font-weight: 500;
                    margin-top: 15px;

                    input{
                        margin-right: 7px;
                    }
                }
            }
        }

        .wpcf7-acceptance{
            label{
                display: inline-flex;
                font-weight: 500;
            }

            input{
                width: auto;
                margin-right: 10px;
            }
        }
    }

    input.wpcf7-submit{
        padding: 20px 34px 20px 34px;
        border-radius: 100px;
        background: $color-primary;
        font-size: 14px;
        font-weight: 700;
        color: $white;
        display: inline-flex;
        float: right;
        transition: 0.2s linear;
        border: none;

        @include max-md{
            float: left;
            width: 100%;
            justify-content: space-between;
            margin-top: 30px;
        }

        &:hover{
            background: $color-primary3;
            transition: 0.2s linear;
        }

        /*i {
            font-size: 30px;
            width: 30px;
            height: 30px;
            margin-top: -9px;
            margin-left: 8px;            

            &:before{
                color: $white;
            }
        }*/
    }
}