// ===== FONTS ===== //

$font-path:                   "../fonts";
$font:                        "Montserrat", Arial, sans-serif;
$font-variant:                "Montserrat", Arial, sans-serif;

$font-family-base:            $font !default;
$font-size-root:              null !default;
$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-sm:                $font-size-base * .875 !default;
$font-size-lg:                $font-size-base * 1.25 !default;

$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-medium:          500 !default;
$font-weight-semibold:        600 !default;
$font-weight-bold:            700 !default;
$font-weight-bolder:          bolder !default;

$font-weight-base:            $font-weight-normal !default;

$line-height-base:            1.5 !default;
$line-height-sm:              1.25 !default;
$line-height-lg:              2 !default;

$headings-margin-bottom:      $spacer * .5 !default;
$headings-font-family:        $font-variant !default;
$headings-font-style:         null !default;
$headings-font-weight:        400 !default;
$headings-line-height:        1.2 !default;
$headings-color:              $color-primary !default;

$h1-font-size:                $font-size-base * 4.750 !default;
$h2-font-size:                $font-size-base * 3.812 !default;
$h3-font-size:                $font-size-base * 3.062 !default;
$h4-font-size:                $font-size-base * 2.438 !default;
$h5-font-size:                $font-size-base * 1.938 !default;
$h6-font-size:                $font-size-base * 1.562 !default;

$display-font-sizes: (
  1: 5rem,
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem
) !default;

$display-font-family:         $font-variant !default;
$display-font-style:          null !default;
$display-font-weight:         300 !default;
$display-line-height:         $headings-line-height !default;

// Body
//
// Settings for the `<body>` element.

$body-text-align:           null !default;
$body-color:                $gray-900 !default;
$body-bg:                   $white !default;

$body-secondary-color:      rgba($body-color, .75) !default;
$body-secondary-bg:         $gray-200 !default;

$body-tertiary-color:       rgba($body-color, .5) !default;
$body-tertiary-bg:          $gray-100 !default;

$body-emphasis-color:       $black !default;

// Links
//
// Style anchor elements.

$link-color:                              $color-primary !default;
$link-decoration:                         underline !default;
$link-shade-percentage:                   20% !default;
$link-hover-color:                        shift-color($link-color, $link-shade-percentage) !default;
$link-hover-decoration:                   null !default;

$stretched-link-pseudo-element:           after !default;
$stretched-link-z-index:                  1 !default;

// Icon links
// scss-docs-start icon-link-variables
$icon-link-gap:               .375rem !default;
$icon-link-underline-offset:  .25em !default;
$icon-link-icon-size:         1em !default;
$icon-link-icon-transition:   .2s ease-in-out transform !default;
$icon-link-icon-transform:    translate3d(.25em, 0, 0) !default;
// scss-docs-end icon-link-variables

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:     1rem !default;

$lead-font-size:              $font-size-base * 1.25 !default;
$lead-font-weight:            300 !default;

$small-font-size:             .875em !default;

$sub-sup-font-size:           .75em !default;

$initialism-font-size:        $small-font-size !default;

$blockquote-margin-y:         $spacer !default;
$blockquote-font-size:        $font-size-base * 1.25 !default;
$blockquote-footer-color:     $gray-600 !default;
$blockquote-footer-font-size: $small-font-size !default;

$hr-margin-y:                 $spacer !default;
$hr-color:                    inherit !default;

$hr-border-color:             null !default; // Allows for inherited colors
$hr-border-width:             var(--#{$prefix}border-width) !default;
$hr-opacity:                  .25 !default;

$vr-border-width:             var(--#{$prefix}border-width) !default;

$legend-margin-bottom:        .5rem !default;
$legend-font-size:            1.5rem !default;
$legend-font-weight:          null !default;

$dt-font-weight:              $font-weight-bold !default;

$list-inline-padding:         .5rem !default;
