.sec-filters{
    margin: 35px 0 0 0;

    @include max-lg{
        margin: 0;
    }

    .col-lg-4{
        @include max-lg{
            padding-left: 0;
            padding-right: 0;
        }
    }

    .col-lg-8{
        @include max-lg{
            margin-top: 10px;
            padding-left: 0;
            padding-right: 0;
        }
    }

    .col-12{
        @include max-lg{
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.filter {
    text-align: right;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: end;

    @include max-lg{
        gap: 15px;
        justify-content: start;
        margin: 30px 0 0 0;
    }

    @include max-md{
        display: grid;
        grid-template-columns: 100%;
    }

    .item-filter{
        position: relative;

        i{
            font-size: 48px;
            width: 15px;
            height: 15px;
            display: block;
            position: absolute;
            right: 30px;
            top: 0;

            @include max-md{
                top: 0;
            }
        }
    }

    @include max-lg{
        text-align: left;
    }

    span{
        font-weight: 700;

        @include max-lg{
            display: block;
            margin: 0 0 20px 0;
        }

        i.icon-down-arrow {
            position: absolute;
            right: 27px;
            font-size: 50px;
            width: 20px;
            height: 20px;
            display: inline-flex;
            top: -2px;

            @include max-lg{
                display: none;
            }
        }
    }

    select{
        height: 44px;
        padding: 10px 35px 10px 20px;
        align-items: center;
        border-radius: 24px;
        border: 1px solid $color-primary3;
        background: #FFF;
        margin-left: 20px;
        appearance: none;

        @include max-lg{
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 10px;
        }

        @include max-md{
            width: 100%;
        }
    }

    button{
        height: 44px;
        padding: 10px 35px 10px 20px;
        align-items: center;
        border-radius: 24px;
        border: 1px solid $color-primary3;
        background: $white;
        margin-left: 20px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        transition: 0.2s linear;

        @include max-lg{
            margin-left: 0;
            margin-right: 10px;
            margin-bottom: 5px;
        }

        &:hover{
            background: $color-primary3;
            color: $white;
            transition: 0.2s linear;
        }

        &.active{
            background: $color-primary3;
            color: $white;
            transition: 0.2s linear;
        }
    }
}

.menu-filter{
    list-style: none;
    padding: 0;
    display: inline-flex;

    &.filter-items{
        li{
            &:nth-child(2){
                a{
                    border-width: 1px 0 1px 0;
                }
            }
        }
    }

    li{

        &:first-child{
            a{
                border-radius: 100px 0 0 100px;
            }
        }

        &:last-child{
            a{
                border-radius: 0 100px 100px 0;
            }
        }

        a{
            text-decoration: none;
            font-size: 14px;
            line-height: 18px;
            font-weight: 700;
            padding: 13px 30px;
            display: block;
            transition: 0.2s linear;
            border: 1px solid #014150;
            border-radius: 0;
            background: $white;

            @include max-md{
                padding: 13px 18px;
            }

            &:hover{
                transition: 0.2s linear;
                background-color: #DCF4F9;
                color: #014150;
            }

            &.active{
                background-color: #014150;
                color: #fff;
            }
        }
    }
}