.col2-404 {
    display: flex;
    gap: 40px;

    @include max-lg{
        display: block;
    }

    .icon{
        @include max-lg{
            margin: 30px 0;
        }
    }

    .text{
        p{
            color: $color-primary;
            font-size: 20px;
            font-style: normal;
            font-weight: $font-weight-normal;
            line-height: 160%;
        }
    }
}

.bt404{
    text-align: center;
    margin: 50px 0 120px 0;

    @include max-lg{
        margin: 0 0 50px 0;
    }

    a{
        @include max-lg{
            margin: 0 !important;
        }
    }
}