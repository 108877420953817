.image-post{
    img{
        width: 100%;
    }
}

.contentcols-posts{
    padding-top: 80px;
    padding-bottom: 120px;

    @include max-lg{
        padding-top: 30px;
        padding-bottom: 60px;
    }

    &.general{
        padding-top: 40px;

        @include max-lg{
            padding-top: 0;
        }
    }

    .author {
        font-size: 14px;
        font-weight: 700;
        line-height: 160%;
        color: $color-primary;
        display: inline-flex;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid #53B3C6;

        i {
            font-size: 36px;
            margin-right: 5px;
            margin-left: -5px;
        }
    }

    .dates-share{
        margin-top: 10px;

        @include max-lg{
            display: grid;
            grid-template-columns: 50% 50%;
            margin-bottom: 30px;
        }

        .date-post {
            font-size: 14px;
            line-height: 160%;
            color: $color-primary;
            display: inline-flex;
            align-items: center;
            width: 100%;
    
            i {
                font-size: 36px;
                margin-right: 5px;
                margin-left: -5px;
            }
        }
    
        .shared-post {
            font-size: 14px;
            line-height: 160%;
            color: $color-primary;
            
            a{
                text-decoration: none;
                display: inline-flex;
                width: 100%;
                align-items: center;

                @include max-lg{
                    justify-content: end;
                }
            }
    
            i {
                font-size: 36px;
                margin-right: 5px;
                margin-left: -5px;
            }
        }
    }

    .content-post{
        margin-left: 30px;

        @include max-lg{
            margin-left: 0;
        }

        .p-large{
            color: $color-primary;
            margin: 0 0 40px 0;
        }

        .image{
            margin: 40px 0;

            img{
                width: 100%;
            }
        }

        .button-post{
            margin: 40px 0;

            a{
                display: inline-flex;
                padding: 13px 20px;
                font-size: 14px;
                line-height: normal;
                font-weight: 700;
                border-radius: 50px;
                border: 1px solid rgba(1, 65, 80, 0.40);
                text-decoration: none;
                transition: 0.2s linear;

                &:hover{
                    background: #D4F0F6;
                    transition: 0.2s linear;
                }

                i{
                    font-size: 36px;
                    width: 32px;
                    height: 10px;
                    margin-top: -9px;
                    margin-left: 7px;
                    color: $color-primary2;
                }
            }
        }

        .tags-post{
            display: flex;
            gap: 20px;

            a{
                padding: 5px 15px;
                color: $white;
                font-size: 14px;
                font-weight: 400;
                line-height: 160%;
                background-color: #006E87;
                border-radius: 100px;
                text-decoration: none;
            }
        }

        .slider-images{
            margin: 40px 0;

            .swiperGalleryPost{
                overflow: initial;
                padding-bottom: 120px;

                @include max-lg{
                    overflow: hidden;
                }

                .pagination {
                    .line {
                        width: 120px;
                        height: 1px;
                        background: $color-primary3;
                        position: absolute;
                        right: 150px;
                        bottom: 30px;
                    }
        
                    .swiper-button-prev {
                        left: inherit;
                        right: 75px;
                        bottom: 0px;
                        top: inherit;
                        width: 60px;
                        height: 60px;
                        background: $color-primary;
                        border-radius: 100px;
                        transition: 0.3s linear;
        
                        &.swiper-button-disabled {
                            width: 40px;
                            height: 40px;
                            bottom: 10px;
                            right: 80px;
                            transition: 0.3s linear;
                        }
        
                        i {
                            font-size: 40px;
                            transform: rotate(-180deg);
        
                            &:before {
                                color: $white;
                                display: inherit;
                            }
                        }
        
                        &:after {
                            display: none;
                        }
                    }
        
                    .swiper-button-next {
                        right: 0;
                        bottom: 0px;
                        top: inherit;
                        width: 60px;
                        height: 60px;
                        background: $color-primary;
                        border-radius: 100px;
                        transition: 0.3s linear;
        
                        &.swiper-button-disabled {
                            width: 40px;
                            height: 40px;
                            bottom: 10px;
                            right: 15px;
                            transition: 0.3s linear;
                        }
        
                        i {
                            font-size: 40px;
        
                            &:before {
                                color: $white;
                                display: inherit;
                            }
                        }
        
                        &:after {
                            display: none;
                        }
                    }
                }
    
                img{
                    width: 100%;
                }
            }
        }
        
    }
}

.related-posts{
    padding-bottom: 150px;

    @include max-lg{
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 80px;
    }

    .swiper-wrapper {
        @include max-lg{
            padding: 0 20px;
            gap: 20px;
        }
    }

    .hed {
        display: inline-flex;
        width: 100%;

        @include max-lg{
            padding-left: 20px;
            padding-right: 20px;
        }

        .line{
            width: 100%;
            height: 1px;
            background: #006E87;
        }
    
        h2{
            background: $color-primary4;
            margin-top: -25px;
            width: fit-content;
            padding-right: 40px;
            font-size: 2.4375rem;
        }
    }

    .box-card{
        margin: 0;
    }

}